.strate-footer-comment-venir{
	position: relative;
	@extend %patternBG-top;
	&:before{
		top: -242px;
		background: $c_bg-light;
		z-index: -1;
		@include tablet-portrait{
			top: -211px;
		}
		@include mobile{
			top: -171px;
		}
	}
	&:after{
		content: '';
		position: absolute;
		top: -180px;
		left: 0;
		bottom: -40px;
		right: 0;
		background-color: $c_bg-light;
		z-index: -1;
		@include mobile{
			top: -140px;
		}
	}

	.wrapper{
		&:after{
			content: '';
			display: block;
			clear: both;
		}
	}
	.texte{
		display: block;
		float: left;
		width:calc(100% - 585px);
		padding-right: 20px;
		padding-top: 40px;
		@include tablet-portrait{
			width: 100%;
		}
	}
	.map{
		display: block;
		float: left;
		width: 585px;
		@include tablet-portrait{
			width: 100%;
		}
		figure{
			background-color: transparent;
			&.desktop{
				display: block;
				@include mobile{
					display: none;
				}
			}
			&.mobile{
				display: none;
				@include mobile{
					display: block;
				}
			}

			img{
				margin:0 auto;
				max-width: 100%;
				height:auto;
			}
		}
	}
}