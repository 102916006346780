/* ------------------
--- CSS GLOBAL + DES CLASSES UTILES ---
------------------ */

html, body {
	height: 100%;
}

html {
	font-size: 100%;
	margin-top: 0 !important;
}

body {
	height: 100%;
	font-family: $f_txt;
	font-size: 13px;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	color: $c_txt;
	margin: 0;
	padding: 0;
	overflow-x: hidden;

	&.no-scroll {
		overflow: hidden;
	}

	&.fixed {
		@extend %center;
		position: fixed;
	}
}


main {
	position: relative;
}

[class^="icon-"], [class*=" icon-"] {
	@include addTransition();
}

input[type="date"]::-webkit-inner-spin-button,
input[type="date"]::-webkit-calendar-picker-indicator {
	display: none;
	-webkit-appearance: none;
}



// @extend
// ==========================================================================
%center {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	width: 100%;
	height: 100%;
}

%aligncenter {
	display: flex;
	display: -ms-flexbox;
	align-items: center;
	justify-content: center;
}

%appearance {
	-webkit-appearance: none;
	resize: none;
	box-shadow: none;
	border-radius: 0;
	border: none;
	outline: none;
	&::-ms-clear{
		display: none;
	}
}

