.bloc-image-seule {
	display: flex;
	flex-direction: column;

	figure {
		background-color: transparent;

		img{
			border-radius: 50px 50px 5px 50px;
			@include mobile{
				border-radius: 30px 30px 5px 30px;
			}
		}
	}
}