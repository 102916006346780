// Tooltip : utilisé lorsqu'on est obligé d'imbriquer le texte dans un lien, parce que
// ==========================================================================
.tooltiptext {
	@include ft($f_txt, 12px, 300, $c_white, 15px);
	position: absolute;
	top: calc(-100% + 10px);
	left: 50%;
	padding: 4px;
	width: 106px;
	margin-left: -60px;
	visibility: hidden;
	white-space: nowrap;
	background-color: $c_dark;
	box-shadow: 0 0 20px rgba($c_dark, 0.15);
	border-radius: 5px;
	text-align: center;
	z-index: 10;
	@include addTransition(visibility, 0.1s, ease-in-out);

	.no-hover & {
		display: none;
	}

	&:after {
		content: " ";
		position: absolute;
		top: 100%; /* At the top of the tooltip */
		left: 50%;
		margin-left: -8px;
		border-width: 8px;
		border-style: solid;
		border-color: $c_dark transparent transparent transparent;
	}
}