.bloc-experiences {
  .text-styles {
    margin-bottom: 39px;
  }

  .card-experiences {
    display: flex;
    flex-wrap: wrap;
    margin-top: -20px;
    position: relative;
    justify-content: center;

    &:before {
      left: -60px;
      top: -70px;
      @extend %feuille-quadrille-bleue;
      transform: rotate(-16deg);
      @include mobile {
        left: -140px;
        top: -40px;
      }
    }
  }

  .card-experience {
    width: 33%;
    padding-left: 10px;
    padding-right: 10px;
    position: relative;
    overflow: hidden;
    margin-top: 20px;
    height: 394px;


    @include min-tablet-portrait {
      &:nth-child(1), &:nth-child(3) {
        margin-top: 57px;
      }
      &:nth-child(2), &:nth-child(5) {
        height: 431px;

        .image {
          .no-photo {
            height: 431px;
          }

          figure, img {
            height: 431px;
          }
        }
      }
    }

    @include tablet-portrait {
      width: 50%;
    }

    @include mobile {
      width: 100%;
      padding-left: 0;
      padding-right: 0;
    }


    &:nth-child(odd) {
      .image {
        border-radius: 50px 50px 0 50px;

        .no-photo, figure, img {
          border-radius: 50px 50px 0 50px;
        }
      }

      .caption {
        .caption-container {
          .caption-contenu {
            &:before {
              border-radius: 50px 50px 0 50px;
            }
          }
        }
      }
    }

    &:nth-child(even) {
      .image {
        border-radius: 50px 50px 50px 0;

        .no-photo, figure, img {
          border-radius: 50px 50px 50px 0;
        }
      }

      .caption {
        .caption-container {
          .caption-contenu {
            &:before {
              border-radius: 50px 50px 50px 0;
            }
          }
        }

      }
    }

    a {
      &:hover {
        cursor: pointer;

        .image {
          &:after {
            @include addTransition();
            visibility: visible;
            opacity: 0.75;
          }
        }

        .btn-round {
          visibility: visible;
        }
      }
    }

    .image {
      height: 100%;
      width: 100%;
      position: relative;
      overflow: hidden;
      @extend %feuille-pleine;

      &:after {
        @include addTransition();
        opacity: 0;
        z-index: 2;
        visibility: hidden;
      }

      figure {
        background: none;
      }

      .no-photo {
        height: 394px;
        @extend %bgTtB;
      }

      figure, img {
        height: 394px;
      }

    }

    .caption {
      position: absolute;
      padding: 30px 54px 30px 30px;
      bottom: 0;
      height: 100%;
      display: flex;
      align-items: flex-end;
      left: 10px;
      right: 10px;
      pointer-events: none;
      justify-content: space-between;

      @include desktop {
        padding: 30px;
      }

      @include mobile {
        left: 0;
        right: 0;
      }

      .caption-container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;

        .caption-contenu {
          display: flex;
          max-width: 220px;
          height: 100%;
          flex-direction: column;

          > * {
            z-index: 2;
          }

          &:before {
            content: "";
            display: block;
            width: 100%;
            height: 100%;
            background: linear-gradient(180deg, rgba(2, 19, 46, 0) 0%, rgba(2, 19, 46, 1) 100%);
            opacity: 0.50;
            position: absolute;
            bottom: 0;
            left: 0;
            z-index: 1;
            pointer-events: none;
          }
        }
      }

      .surtitre-card-experience {
        margin-bottom: 5px;
        @include ft($f_txt, 20px, 600, $c_white, 26px);
      }

      .title-card-experience {
        @include ft($f_hx, 30px, 300, $c_white, 28px);
      }

      .location {
        color: $c_white;

        &:before {
          color: $c_white;
        }
      }

      .btn-round {
        background: none;
        flex: none;
        visibility: hidden;
        height: 30px;
        width: 30px;
        transition: none;
        border: 1px solid $c_white;

        &:after {
          content: "\e90b";
          color: $c_white;
          font-size: 14px;
          transition: none;
        }

        &:hover {
          border: 1px solid $c_txt;
        }
      }
    }
  }
}