// Container sans colonnes
// ==========================================================================
/*
.wrapper {
	display: block;
	position: relative;
	margin-left: auto;
	margin-right: auto;
	max-width: $wrapper-width;

	// Les media queries et mixins suivantes ont l'objectif de déterminer les marges des wrappers
	@media screen and (max-width: $wrapper-width+$border-gutter+$border-gutter) {
		margin-left: $border-gutter;
		margin-right: $border-gutter;
	}
	@include tablet-landscape {
		margin-left: 40px;
		margin-right: 40px;
	}
	// Breakpoint mobile, on met les marge de l'écran pour tout
	@include mobile {
		margin-left: 20px;
		margin-right: 20px;
	}

	!***************
	LISTE DES WRAPPERS DISPONIBLES (à partir du plus petit au plus grand)
	****************!
	&.wrapper-extra-small {
		@media screen and (min-width: $wrapper-extra-small-width+$border-gutter+$border-gutter+$grid-gap) {
			max-width: $wrapper-extra-small-width !important;
			margin-left: auto !important;
			margin-right: auto !important;
		}
	}

	&.wrapper-small {
		@media screen and (min-width: $wrapper-small-width+$border-gutter+$border-gutter+$grid-gap) {
			max-width: $wrapper-small-width !important;
			margin-left: auto !important;
			margin-right: auto !important;
		}
	}

	&.wrapper-medium {
		@media screen and (min-width: $wrapper-medium-width+$border-gutter+$border-gutter+$grid-gap) {
			max-width: $wrapper-medium-width !important;
			margin-left: auto !important;
			margin-right: auto !important;
		}
	}

	&.wrapper-large {
		max-width: $wrapper-width;
		@include wide {
			max-width: $wrapper-large-width !important;
		}

		@include tablet-landscape {
			margin-left: 40px;
			margin-right: 40px;
		}
		@include mobile {
			margin-left: 20px;
			margin-right: 20px;
		}
	}

	&.wrapper-full {
		width: 100vw;
		max-width: none !important;
		margin-left: 0 !important;
		margin-right: 0 !important;
	}
}*/

// Container avec colonnes
// NOTE IMPORTANTE : Vu qu'on peut avoir deux tailles de gouttières dans le site (mais dans votre projet vous pouvez même avoir plus)
// On a décidé d'avoir une mixin qui prend comme argument la taille de la gouttière
// On a la premiere taille utilisé par défaut partout dès qu'on a un wrapper-columns
// Et une deuxième taille dès qu'on a la classe "small-gap"
// La mixin utilisé est dans le fichier scss "mixins"
// ==========================================================================
.wrapper-columns:not(.small-gap) {
	@include wrapper-columns($grid-gap);
}

// Si on a la classe "small-margin" au même niveau que le "wrapper-columns" on utilise la variable $small-margin que dans le framework est égale à 20px.
// L'objectif est d'avoir les mêmes calculs mais effectués avec une taille de gouttière plus petite
.wrapper-columns.small-gap {
	@include wrapper-columns($small-margin);
}

// Grille en colonnes
// ==========================================================================
.col-16, .col-20, .col-25, .col-33, .col-50, .col-75, .col-66, .col-100 {
	display: block;
	box-sizing: border-box;
}

.col-20 {
	width: calc(20% - 20px);
	@include tablet-landscape {
		width: calc(25% - 20px);
	}
}

.col-25 {
	width: 25%;
}

.col-33 {
	width: 33.33333%;
	@include tablet-small {
		width: 50%;
	}
}

.col-50 {
	width: 50%;
}

.col-66 {
	width: 66.66666%;
	@include tablet-small {
		width: 50%;
	}
}

.col-75 {
	width: 75%;
}

.col-100 {
	width: 100%;
}

@include tablet-portrait {
	.col-25, .col-75 {
		width: 50%;
	}
}

@include mobile {
	.col-20, .col-25, .col-33, .col-50, .col-66, .col-75 {
		float: none;
		clear: both;
		margin: 0 auto;
		width: 100%;
	}
}