
.bloc-image-texte {
	display: grid;
	grid-template-areas: "text image";
	grid-template-columns: 1fr 1fr;
	gap: var(--grid-gap);
	align-items: start;

	@include tablet-small {
		grid-gap: $grid-gap-small;
	}

	// -- modifiers
	// Texte à droite et photo à gauche -> inversion par rapport au DOM
	&.--text-right {
		grid-template-areas: "image text";

		@include min-desktop {
			.col-text {
				padding-right: 0;
			}
		}
	}

	// Texte à gauche et photo à droite -> texte aligné à droite (seulement en tablet et >)
	&.--text-left {
		@include min-desktop {
			.col-text {
				padding-left: 0;
			}
		}

		@include min-mobile {
			.col-text {
				text-align: right;

				// alignement à droite spécial pour les listes à puces
				ul {
					margin-left: 0;
					margin-right: 0;

					li {
						padding-left: initial;
						padding-right: 1.6em;

						&:before {
							left: initial;
							right: 4px;
						}
					}
				}
			}
		}
	}

	// Toujours image en premier et texte aligné à gauche sur mobile
	&.--text-right,
	&.--text-left {
		@include mobile {
			grid-template-areas: "image"
								 "text";
			grid-template-columns: 1fr;
		}
	}

	.col-text {
		grid-area: text;
		margin-top: auto;		// texte centré verticalement seulement si plus petit que l'image
		margin-bottom: auto;

		@include mobile {
			padding-bottom: var(--grid-gap);
		}
	}

	.col-image {
		grid-area: image;
	}

	.image {
		figure {
			overflow: hidden;
			background-color: initial;
			border-radius:50px 50px 5px 50px;
			@include mobile{
				border-radius: 30px 30px 5px 30px;
			}
            figcaption{
                display: none;
            }
		}

		img {
			height: auto;
			width: 100%;
		}
	}
}

