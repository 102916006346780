// ==============================================
//
//        UNCATEGORIZED MIXINS
//
// ==============================================


//  Géometrie
// -----------------------------------------------

/// Crée un triangle, son positionnement se fait en dehors du mixin
/// @param {String} $direction - Orientation du triangle `up`, `right`, `down` plus des variantes pour les "corner triangles"
/// @param {Color} $color - Couleur du triangle 
/// @param {Length} $width - Longueur du triangle
/// @param {Length} $height [$width] - Hauteur du triangle (si non renseigné -> triangle equilatéral) 

@mixin triangle($direction, $color, $width, $height: $width) {
  @if not index(down up right left down-right down-left up-right up-left, $direction) {
    @warn "`#{$direction}` is not a valid parameter";
  }

  display: inline-block;    
  width: 0;
  height: 0;
  $half-width: $width/2;
  $half-height: $height/2;
  
  // basic triangles : ▼ ▲ ▶ ◀
  @if $direction == 'down' {  
    border-top  : $height solid $color;
    border-left : $half-width solid transparent;
    border-right: $half-width solid transparent;
  } @else if $direction == 'up' {
    border-bottom: $height solid $color;
    border-left  : $half-width solid transparent;
    border-right : $half-width solid transparent;
  } @else if $direction == 'right' {
    border-left  : $width solid $color;
    border-top   : $half-height solid transparent;
    border-bottom: $half-height solid transparent;
  } @else if $direction == 'left' {
    border-right : $width solid $color;
    border-top   : $half-height solid transparent;
    border-bottom: $half-height solid transparent;
  
  // corner triangles : ◥ ◢ ◤ ◣ 
  } @else if $direction == 'up-right' {
    border-top   : $half-height solid $color;
    border-left  : $half-width solid transparent;
    border-right : $half-width solid $color;
    border-bottom: $half-height solid transparent;
  } @else if $direction == 'down-right' {
    border-top   : $half-height solid transparent;
    border-left  : $half-width solid transparent; 
    border-right : $half-width solid $color;
    border-bottom: $half-height solid $color;
  } @else if $direction == 'up-left' {
    border-top   : $half-height solid $color;
    border-left  : $half-width solid $color;
    border-right : $half-width solid transparent;
    border-bottom: $half-height solid transparent;
  } @else if $direction == 'down-left' {
    border-top   : $half-height solid transparent;
    border-left  : $half-width solid $color;
    border-right : $half-width solid transparent;
    border-bottom: $half-height solid $color;
  } 
}



/// Crée un cercle, son positionnement se fait en dehors du mixin
/// @param {Length} $width - Diamètre du cercle
/// @param {Color} $bg-color - Couleur de fond du cercle 

@mixin circle($size, $bg-color:null) {
  width: $size;
  height: $size;
  border-radius: 50%;
  @if $bg-color { background-color: $bg-color; }
}



//  Autres
// -----------------------------------------------

// Génére une transition
@mixin addTransition($effect: all, $duration: 0.2s, $easeing:ease) {
  $anim: $effect $duration $easeing;
  transition: $anim;
}


// Comportement de hover sauf si touchscreen
@mixin can-hover() {
	&:hover,
	&:focus {
		body:not(.no-hover) & {
			@content;
		}
	}
}


// Clearfix pour les floats
@mixin clearfix {
  &:before,
  &:after {
    content: "";
    display: table;
  }
  &:after {
    clear: both;
  }
}
