.header-edito {
	&.header-post{
		.cats{
            margin: 0 0 10px 0;
			.cat{
                @include ft($f_txt, 20px, 600, $c_white, 26px);
                @include mobile{
                    font-size: calculateRem(16px);
                    line-height: calculateRem(20px);
                }
				&:not(:last-child){
					margin-right: 0;
					&:after{
						content: ', ';
					}
				}
			}
		}

		.published{
			position: relative;
			color: white;
			font-size: 16px;
			line-height: 25px;
			padding-left: 18px;
			&:before{
				content: '\e912';
				font-family:'icomoon';
				font-size: 12px;
				line-height: 1;
				top: 7px;
				left: 0;
				position: absolute;
			}
		}
	}
}