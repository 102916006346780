// style commun au single agenda et single sit
.page-single {
	.text-styles {
		p {
			font-size: 16px;
		}
	}

	> .content {
		position: relative;
		@include tablet-landscape {
			top: auto;
		}
	}

	& + #footer {
		padding-bottom: 100px;
	}


	//Header
	.header-edito {
		position:relative;
		&.header-full-image {
			.price {
				right: 100px;
				bottom:75px;
			}
		}

		.general-container{
			overflow: visible;
		}

		.price {
			text-align:right;
			@include min-tablet-portrait {
				right: 0;
				bottom: 0;

				position: absolute;
				font-size:18px;
				display:flex;
				z-index:1;
				flex-direction:column;
				> * {
					color: $c_txt;
				}

				.second-text {
					font-size:35px;
					span{
						font-size: 16px;
					}
				}

				.gratuit {
					color: $c_txt;
					font-weight: 700;
				}
			}

			@include tablet-portrait {
				margin-top: 20px;
				> * {
					color: $c_white;
				}
			}

			&:after {
				content:"";
				position: absolute;
				right: -190px;
				bottom:-200px;
				width: 447px;
				height: 298px;
				color: $c_txt;
				margin:0 auto;
				display:flex;
				align-items:center;
				flex-direction: column;
				justify-content: center;
				z-index: -1;
				pointer-events: none;
				background-repeat: no-repeat;
				background-image: url("../images/deco/feuille-blanche.svg");
			}
		}
	}


	// single-contact
	.single-contact {
		address {
			display: flex;
			font-size: 16px;
			position: relative;
			align-items: flex-start;
			padding-left: 30px;
			line-height: 24px;

			&:before {
				content: "\e93f";
				@include icomoon();
				display: block;
				color: $c_main;
				font-size: 20px;
				margin-right: 10px;
				position: absolute;
				left: 0;
			}
		}

		.container {
			display: flex;
			align-items:center;
			flex-wrap: wrap;
		}

		.contact{
			margin-top: 10px;
			font-size: 0;
			a {
				margin-top: 10px;
				margin-bottom: 0;
				margin-right: 20px;
			}
		}

		.social-links, .reseaux {
			margin-top: 10px;
			a {
				width: 40px;
				height: 40px;
				padding: 0;
				display: inline-flex;
				align-items:center;
				justify-content:center;
				margin-top: 10px;
				margin-bottom: 0;
				&:not(:last-child){
					margin-right: 10px;
				}
				span{
					font-size: 16px;
					line-height: 1;
				}
			}
		}
	}


	//contact map
	.contact-map {
		margin-top: 40px;
		display: block;
		z-index: 1;
		.maps{
			height: 475px;
			position: relative;
			z-index:0;
		}

		.content-btn {
			width:100%;
			margin-top:15px;
			text-align:right;
		}
	}
}