.marker-cluster-small {
	background-color: $c_sub;
}
.marker-cluster-small div {
	background-color: $c_sub;
}

.marker-cluster-medium {
	background-color: $c_sub;
}
.marker-cluster-medium div {
	background-color: $c_sub;
}

.marker-cluster-large {
	background-color: $c_sub;
}
.marker-cluster-large div {
	background-color: $c_sub;
}

/* IE 6-8 fallback colors */
.leaflet-oldie .marker-cluster-small {
	background-color: $c_sub;
}
.leaflet-oldie .marker-cluster-small div {
	background-color: $c_sub;
}

.leaflet-oldie .marker-cluster-medium {
	background-color: $c_sub;
}
.leaflet-oldie .marker-cluster-medium div {
	background-color: $c_sub;
}

.leaflet-oldie .marker-cluster-large {
	background-color: $c_sub;
}
.leaflet-oldie .marker-cluster-large div {
	background-color: $c_sub;
}

.marker-cluster {
	background-clip: padding-box;
	border-radius: 20px;
}
.marker-cluster div {
	width: 30px;
	height: 30px;
	margin-left: 5px;
	margin-top: 5px;

	text-align: center;
	border-radius: 15px;
	color:#fff;
	font-size: 12px;
	font-weight: 700;
	//font: 12px "Helvetica Neue", Arial, Helvetica, sans-serif;
}
.marker-cluster span {
	line-height: 30px;
}