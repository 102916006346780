@font-face {
	font-family: 'icomoon';
	src: url('../fonts/icomoon/fonts/icomoon.eot?xfoi9s');
	src: url('../fonts/icomoon/fonts/icomoon.eot?xfoi9s#iefix') format('embedded-opentype'),
	url('../fonts/icomoon/fonts/icomoon.woff2?xfoi9s') format('woff2'),
	url('../fonts/icomoon/fonts/icomoon.ttf?xfoi9s') format('truetype'),
	url('../fonts/icomoon/fonts/icomoon.woff?xfoi9s') format('woff'),
	url('../fonts/icomoon/fonts/icomoon.svg?xfoi9s#icomoon') format('svg');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

[class^="icon-"], [class*=" icon-"] {
	/* use !important to prevent issues with browser extensions that change fonts */
	font-family: 'icomoon' !important;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;

	/* Better Font Rendering =========== */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.icon-tripadvisor:before {
	content: "\e948";
}
.icon-linkedin:before {
	content: "\e947";
}
.icon-pmr:before {
	content: "\e946";
}
.icon-vague:before {
	content: "\e945";
}
.icon-location:before {
	content: "\e93f";
}
.icon-ballons:before {
	content: "\e90d";
}
.icon-boussole:before {
	content: "\e90e";
}
.icon-bras:before {
	content: "\e915";
}
.icon-brouette:before {
	content: "\e916";
}
.icon-buste:before {
	content: "\e917";
}
.icon-cerf-volant:before {
	content: "\e918";
}
.icon-chateau:before {
	content: "\e919";
}
.icon-chaussure:before {
	content: "\e91a";
}
.icon-cocktail:before {
	content: "\e92b";
}
.icon-groupe:before {
	content: "\e92c";
}
.icon-lit:before {
	content: "\e930";
}
.icon-lotus:before {
	content: "\e931";
}
.icon-panier:before {
	content: "\e932";
}
.icon-plat:before {
	content: "\e937";
}
.icon-poterie:before {
	content: "\e938";
}
.icon-sac:before {
	content: "\e93a";
}
.icon-stand:before {
	content: "\e93b";
}
.icon-tableau:before {
	content: "\e93c";
}
.icon-velo:before {
	content: "\e93e";
}
.icon-restau:before {
	content: "\e914";
}
.icon-avion:before {
	content: "\e913";
}
.icon-check:before {
	content: "\e911";
}
.icon-valid:before {
	content: "\e912";
}
.icon-menu:before {
	content: "\e90c";
}
.icon-tiktok:before {
	content: "\e906";
}
.icon-arrow-top:before {
	content: "\e907";
}
.icon-arrow-bottom:before {
	content: "\e908";
}
.icon-arrow-left:before {
	content: "\e909";
}
.icon-arrow-long:before {
	content: "\e90a";
}
.icon-arrow-right:before {
	content: "\e90b";
}
.icon-ico-oeil:before {
	content: "\e944";
}
.icon-ico-person:before {
	content: "\e943";
}
.icon-ico-paiement:before {
	content: "\e942";
}
.icon-ico-arrow-round:before {
	content: "\e93d";
}
.icon-ico-document:before {
	content: "\e940";
}
.icon-ico-langues:before {
	content: "\e941";
}
.icon-ico-coeur-rempli:before {
	content: "\e939";
}
.icon-ico-moins:before {
	content: "\e935";
}
.icon-ico-plus:before {
	content: "\e936";
}
.icon-ico-credits:before {
	content: "\e934";
}
.icon-ico-email:before {
	content: "\e900";
}
.icon-ico-appel:before {
	content: "\e901";
}
.icon-ico-link:before {
	content: "\e902";
}
.icon-ico-refresh:before {
	content: "\e903";
}
.icon-ico-social-share:before {
	content: "\e904";
}
.icon-ico-filtres:before {
	content: "\e905";
}
.icon-ico-agrandir:before {
	content: "\e90f";
}
.icon-ico-play:before {
	content: "\e910";
}
.icon-ico-natation:before {
	content: "\e91b";
}
.icon-ico-parking:before {
	content: "\e91c";
}
.icon-ico-wifi:before {
	content: "\e91d";
}
.icon-ico-animal:before {
	content: "\e91e";
}
.icon-ico-restaurant:before {
	content: "\e91f";
}
.icon-ico-epis:before {
	content: "\e920";
}
.icon-ico-cle:before {
	content: "\e921";
}
.icon-ico-etoile:before {
	content: "\e922";
}
.icon-ico-agenda:before {
	content: "\e923";
}
.icon-ico-youtube:before {
	content: "\e933";
}
.icon-ico-pinterest:before {
	content: "\e924";
}
.icon-ico-instagram:before {
	content: "\e925";
}
.icon-ico-twitter:before {
	content: "\e926";
}
.icon-ico-facebook:before {
	content: "\e927";
}
.icon-ico-close:before {
	content: "\e928";
}
.icon-ico-telechargement:before {
	content: "\e929";
}
.icon-ico-arrow-down:before {
	content: "\e92a";
}
.icon-ico-search:before {
	content: "\e92d";
}
.icon-ico-coeur:before {
	content: "\e92e";
}
.icon-ico-panier:before {
	content: "\e92f";
}
