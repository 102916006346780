// Styles communs à tous les sliders avec plusieurs éléments visibles à la fois
// (pas donc pour les full-sliders qui souvent utilisent le composant image wysiwyg)
// ==========================================================================

.multi-slider {
	& .scroll-mode {
		width: calc(((100vw - 100%) / 2) + 100%) !important;
	}
}

