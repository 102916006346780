// BLOC VIDEO
// ==========================================================================
.bloc-video {
    z-index: 10;

	.container {
		position: relative;
		cursor: pointer;
		overflow: hidden;
		border-radius: 50px;
		@include mobile{
			border-radius: 30px;
		}

		.mask-video {
			height: 100%;
			z-index: 10;
			opacity: 1;
			transition: all 0.7s ease-in-out;
			@extend %center;

			@include mobile {
				height: 100%;
			}

			& ~ .embed-container {
				opacity: 0;
			}

			&.hide-mask {
				figure {
					opacity: 0;

					@media screen and (max-width: 750px) {
						opacity: 1;
					}
				}

				& + .embed-container {
					opacity: 1;
					z-index: 100;
					pointer-events: auto;
				}
			}

			figure {
				background-color: transparent;
				position: relative;
				height: auto;
				opacity: 1;
				transition: all 0.7s ease-in-out;

				@include mobile {
					height: 100%;
				}

				img {
					height: 100%;
				}
			}

			span[class^='icon-']{
				position: absolute;
				top: 50%;
				left: 50%;
				margin-top: -35px;
				margin-left: -35px;
				width: 70px;
				height: 70px;
				border-radius:50%;
				@extend %bgTtB;
				color: white;
				font-size: 20px;
				line-height: 1;
				display: flex;
				align-items:center;
				justify-content:center;
				@include addTransition();
			}
		}

		.embed-container {
			@include aspect-ratio(1280, 720);
			height: 100%;
			min-height: 200px;
			transition: all 0.7s ease-in-out;

			iframe, video {
				@extend %center;
			}
		}
	}

	&:hover, &:focus {
		body:not(.no-hover) & {
			span[class^='icon-']{
				color: $c_bg;
			}
		}
	}

}