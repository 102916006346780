

// Définition de l'espace vertical entre les blocs, sections et strates
// ==========================================================================

.section {
    & + & {
        margin-top: var(--section-spacing);
    }
}

.bloc {
    & + & {
        margin-top: var(--bloc-spacing);
    }
}

.strate {
    position: relative;

    & + & {
        margin-top: var(--strate-spacing);
    }

    // Styles pour le titre qui est commun à plusieurs strates footer (il reprend pas la taille des headings qu'on à déjà)
    .title {
        @include ft($f_hx, 40px, 700, $c_main, 1);
        display: block;

        @include min-mobile {
            max-width: 80%;
        }
    }
}

#footer {
    margin-top: $border-gutter;
    @include tablet-portrait{
        padding-bottom: 80px;
    }
}


