// Styles générales pour le bouton de base
button {
    border: none;
    outline: none;
    background: none;
    padding: 0;
    margin: 0;
    @include addTransition();

    &:focus {
        outline: none;
        -webkit-touch-callout: none;
        -webkit-user-select: none;
        -khtml-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
    }
}

// Styles pour la classe btn, btn et btn-primary.
.btn {
    display: inline-flex;
    align-items: center;
    width: auto;
    @include ft($f_txt, 14px, 500, $c_white, 26px);
    background-color: $c_main;
    padding: 6px 25px 8px;
    border: none;
    text-decoration: none;
    max-width: 100%;
    text-align: center;
    border-radius: 50px;
    @include addTransition();
    @extend %bgLtR;

    &:after {
        content: '\e90b';
        font-family: 'icomoon';
        position: relative;
        display: inline-block;
        font-size: 14px;
        line-height: 1;
        margin-left: 9px;
        transform: translate3d(0, 0, 0);
        @include addTransition();
    }

    @include mobile {
        padding: 6px 15px 8px;
        line-height: 22px;
    }

    &:hover, &:focus {
        body:not(.no-hover) & {
            cursor: pointer;
            color: white;

            &:after {
                transform: translate3d(5px, 0, 0);
            }
        }
    }

    &.btn-primary {
        background: $c_white;
        border: 1px solid $c_txt;
        padding: 5px 25px 7px;
        color: $c_txt;

        &:after {
            content: none;
        }

        @include mobile {
            padding: 8px 20px 10px;
            line-height: 22px;
        }

        &:hover, &:focus {
            body:not(.no-hover) & {
                color: $c_white;
                background-color: $c_txt;
                border-color: transparent;
            }
        }

        &.itineraire {
            span {
                margin-left: 0;
                margin-right: 8px;
                transition: none;
            }
        }
    }

    &.btn-border-white {
        background: none;
        border: 1px solid $c_white;
        padding: 5px 25px 7px;
        color: $c_white;

        @include mobile {
            padding: 5px 15px 7px;
            line-height: 22px;
		}

		&:hover, &:focus {
			body:not(.no-hover) & {
				color: $c_txt;
				background-color: $c_white;
				border-color: $c_white;
			}
		}
	}

	&.btn-full-white {
		background: $c_white;
		border: 1px solid transparent;
		padding: 5px 25px 7px;
		color: $c_txt;
		@include can-hover {
			background: transparent;
			border-color: $c_white;
		}

	}

	& + .btn {
		margin-top: 0;
	}
}

.btn-close-plein {
    @extend %appearance;
    @extend %aligncenter;
    background: $c_bg;
    border-radius: 50%;
    color: $c_white;

    &:before {
        content: "\e928";
        @include icomoon();
        font-size: 11px;
        @include addTransition();
    }

}


// style pour le bouton de la carte
.btn-carte {
    width: auto;
    background: $c_white;
    border: 1px solid $c_txt;
    @include ft($f_txt, 14px, 500, $c_txt, 26px);
    padding: 6px 25px 8px;
    text-decoration: none;
    height: 48px;
    cursor: pointer;
    position: relative;
    max-width: 100%;
    text-align: center;
    border-radius: 50px;
    @include addTransition();

    &:before {
        position: relative;
        display: block;
        top: -1px;
        content: "\e93f";
        color: $c_txt;
        @include icomoon();
        font-size: 14px;
        @include addTransition();

        @include min-tablet-portrait {
            margin-right: 10px;
        }
    }

    @include tablet-portrait {
        //@extend %bgLtR;
        background-color: $c_main;
        background-image: linear-gradient(to bottom, $c_main, $c_sub);
        border-radius: 50%;
        width: 40px;
        height: 40px;
        border: none;

        &:before {
            color: white;
        }
    }

    @include mobile {
        line-height: 22px;
    }

    &:hover, &:focus {
        body:not(.no-hover) & {
            color: $c_white;
            background-color: $c_txt;
            border-color: transparent;

            &:before {
                color: $c_white;
            }
        }
    }
}

// styles pour le btn filtre
.btn-filtre {
    width: auto;
    background: $c_white;
    border: 1px solid $c_txt;
    @include ft($f_txt, 14px, 500, $c_txt, 26px);
    padding: 6px 25px 8px;
    text-decoration: none;
    cursor: pointer;
    position: relative;
    max-width: 100%;
    height: 50px;
    text-align: center;
    border-radius: 50px;
    @include addTransition();

    @include tablet-portrait {
        border-radius: 50%;
        width: 40px;
        height: 40px;
    }

    @include mobile {
        line-height: 22px;
    }

    &:before {
        position: relative;
        display: block;
        top: -1px;
        content: "\e905";
        color: $c_txt;
        @include icomoon();
        font-size: 14px;
        @include addTransition();

        @include min-tablet-portrait {
            margin-right: 10px;
        }
    }

    &:hover, &:focus {
        body:not(.no-hover) & {
            &:before {
                color: $c_white;
            }

            color: $c_white;
            background-color: $c_txt;
            border-color: transparent;
        }
    }

    .filter-counter:not(:empty) {
        background-color: $c_main;
        @include ft($f_txt, 10px, bold, $c_white, 60px);
        width: 15px;
        height: 15px;
        position: absolute;
        right: 0;
        top: 0;
        bottom: 0;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        transform: translate(50%, 100%);
    }

}

// Styles pour le bouton des favoris qu'on trouve dans certaines cards et donc le bloc grille push édito
.btn-favoris {
    position: absolute;
    padding: 20px;
    font-size: 20px;
    color: $c_white;
    z-index: 5;
    cursor: pointer;

    &:hover, &:focus {
        body:not(.no-hover) & {
            color: $c_main;
        }
    }

    &.favourite {
        &:before {
            content: "\e939";
        }
    }
}

// Styles pour les boutons et les dots utilisés dans les sliders et dans la pagination
.btn-square {
    position: relative;
    width: 60px;
    height: 60px;
    background-color: $c_white;
    border: 1px solid $c_sub;
    border-radius: 0;
    font-size: 0;
    z-index: z-index('btn-slider');
    @include addTransition();

    @include mobile {
        width: 40px;
        height: 40px;
    }

    &:before, &:after {
        @include icomoon();
        font-size: 16px;
        color: $c_main;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        @include addTransition();

        @include mobile {
            font-size: 10px;
        }
    }

    &:hover, &:focus {
        &:not([disabled]) {
            body:not(.no-hover) & {
                cursor: pointer;
                background-color: $c_main;

                &:before {
                    color: $c_white;
                }
            }
        }
    }

}

.btn-round {
    position: relative;
    width: 40px;
    height: 40px;
    background-color: white;
    border: 1px solid $c_bg;
    font-size: 0;
    border-radius: 50px;
    z-index: z-index('btn-slider');
    @include addTransition();

    &:before, &:after {
        @include icomoon();
        font-size: 18px;
        color: $c_bg;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        @include addTransition();
    }

    &:hover, &:focus {
        &:not([disabled]) {
            body:not(.no-hover) & {
                cursor: pointer;
                background-color: $c_bg;

                &:before, &:after {
                    color: white;
                }
            }
        }
    }

}

.nav-buttons {
    .btn-square, .btn-round {
        &:first-of-type {
            &:before {
                content: "\e909";
            }
        }

        &:last-of-type {
            &:before {
                content: "\e90b";
            }
        }

        &[disabled] {
            opacity: 0.25;
        }
    }
}

.nav-dots {
    display: flex;
    align-items: center;

    button {
        position: relative;
        margin-right: 17px;
        background: transparent;
        border-radius: 0;
        border: none;
        font-size: 33px;
        line-height: 1;
        @include mobile {
            font-size: 25px;
            margin-right: 13px;
        }


        &:before {
            content: "\e945";
            font-family: 'icomoon';
            position: relative;
            display: block;
            color: rgba($c_white, 0.4);
            border-radius: 0;
            left: 0;
            margin-left: 0;
            margin-right: 0;
            transition-delay: 0ms;
        }

        &:hover {
            body:not(.no-hover) & {
                cursor: pointer;
                background-color: transparent;

                &:before {
                    color: $c_main;
                }
            }
        }

        &.active {
            &:before {
                color: $c_main;
            }

        }

        &:last-of-type {
            margin-right: 0;
        }
    }

    &.dots-v2 {
        button {
            &:after {
                background-color: rgba($c_main, 0.25);
            }

            &.active {
                &:after {
                    background-color: $c_main;
                }
            }
        }
    }
}

.btn-view-more {
    display: none;
    position: relative;
    width: 30px;
    height: 30px;
    background-color: $c_white;
    border: 1px solid $c_sub;
    text-align: right;
    margin-left: auto;
    margin-right: 0;
    cursor: pointer;

    &:after {
        content: "\e92a";
        @include icomoon();
        font-size: 12px;
        color: $c_dark;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        @include addTransition();
    }

    &:hover, &:focus {
        body:not(.no-hover) & {
            background-color: $c_dark;
            border: 1px solid transparent;;

            &:after {
                color: $c_white;
            }
        }
    }
}