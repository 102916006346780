// Style General - Overlay - All site
// ==========================================================================
.overlay {
	position: fixed;
	top: 0;
	bottom: 0;
	right: 0;
	width: 100vw;
	display: none;
	background: $c_white;
	z-index: z-index('overlay');
	max-width: 640px;
	transform: translate3d(100%, 0, 0);
	transition: transform 400ms cubic-bezier(0.86, 0, 0.07, 1);
	overflow: hidden;

	@include mobile {
		width: 100vw;
		max-width: 100%;
	}


	&:not(.overlay-absolute) {
		.overlay-load & {
			display: block;
		}
	}

	&.open {
		display: block;
		visibility: visible;
		opacity: 1;
		transform: translate3d(0, 0, 0);
	}

	// Arrivée du haut de l'écran
	&.from-top {
		transform: translate3d(0, -100%, 0);

		&.open {
			transform: translate3d(0, 0, 0);
		}
	}

	&.from-bottom {
		transform: translate3d(0, calc(100% + 80px), 0);

		&.open {
			transform: translate3d(0, 0, 0);
		}
	}


	// Arrivée à gauche de l'écran
	&.from-left {
		transform: translate3d(-100%, 0, 0);

		&.open {
			transform: translate3d(0, 0, 0);
		}
	}

	.error-container {
		position: absolute;
		top: 50%;
		left: 50%;
		width: 75%;
		transform: translate(-50%, -50%);
		text-align: center;
		z-index: 999999;

		@include mobile {
			width: 100%;
		}
	}

	.scroller {
		padding: 40px 40px 80px 40px;
		height: 100%;
		overflow-y: auto;

		@include mobile {
			padding: 40px 40px 40px 40px;
		}
		@include mobile {
			padding: 40px 20px 40px 20px;
		}
	}

	.title {
		 @include ft($f_hx, 50px, 300, $c_txt, 1);
		& + * {
			margin-top: 30px;
		}
	 }

	.btn-close {
		@extend %appearance;
		@extend %aligncenter;
		position: absolute;
		top: 40px;
		right: 40px;
		font-size:17px;
		width: 40px;
		height: 40px;
		color: $c_txt;
		z-index: 10000;
		margin: 0;
		padding: 0;
		@include addTransition();

		@include mobile {
			top: 20px;
			right: 20px;
		}

		&:before {
			content: "\e928";
			@include icomoon();
			font-size: 11px;
			@include addTransition();
		}


		&:hover, &:focus {
			body:not(.no-hover) & {
				cursor: pointer;

				&:before {
					color: $c_dark;
				}
			}
		}

		span {
			display: block;
		}
	}

	.bloc-form {
		.frm_submit {
			margin:0;
		}
	}

	// Barre dockée en bas avec le bouton submit et le lien pour faire un reset
	.bottom-part {
		position: absolute;
		left: 0;
		right: 0;
		bottom: 0;
		z-index: 10000;
		margin: 0;
		transform: translate3d(0, 0, 0);
		display: flex;
		align-items: center;
		text-align: center;
		height: 100px;
		background-color: $c_white;
		@include shadow();

		@include mobile {
			height: 70px;
		}

		.reset {
			@include ft($f_txt, $s_p, 500, $c_txt, 24px);
			position: absolute;
			top: 0;
			width: 50%;
			height: 100%;
			display: flex;
			align-items: center;
			justify-content: flex-start;
			left: 0;
			padding-left: 40px;
			cursor: pointer;
			@include addTransition();

			@include mobile {
				font-size: 14px;
				padding-left: 20px;
				max-width: 40%;
				text-align: left;
			}

			&:before {
				content: "\e903";
				@include icomoon();
				font-size: 20px;
				display: block;
				color: $c_txt;
				margin-right: 10px;
				@include addTransition();
			}

			&:hover, &:focus {
				body:not(.no-hover) & {
					color: $c_sub;

					&:before {
						color: $c_sub;
						transform: rotate(180deg);
					}
				}
			}
		}

		.btn {
			position: absolute;
			right: 40px;
			top: 50%;
			transform: translateY(-50%);
			margin: 0;
			text-transform: none;
			@extend %bgTtB;

			&:after {
				content:none;
			}

			@include mobile {
				right: 20px;
			}
		}
	}

}



// Overlay - map
// ==========================================================================
.overlay-map {
	max-width:none;

	.leaflet-top.leaflet-left {
		@include mobile {
			top:20px;
			left:20px;
		}
	}

	.overlay-map__inner  {
		display: flex;
		flex-direction: row;

		@include mobile {
			height:100vh;
			flex-direction:column;
		}


		.btn-close {
			@include mobile {
				background-color:$c_bg;
				color:white;
				display:flex;
				align-items:center;
				justify-content: center;
				border-radius:50%;
				position:absolute;
				&:hover, &:focus {
					body:not(.no-hover) & {
						&:before {
							color: $c_white;
						}
					}
				}

			}
		}

		@include mobile {
			.scroller {
				padding: 40px 0 0 0;
			}
		}

		.wrapper-cards {
			max-width: 640px;
			background-color:white;
			position:relative;
			height: 100vh;
			@include mobile {
				height: 200px;
				overflow:auto;
				z-index: 999;
			}

			@include min-mobile {
				min-width:640px;
			}

			.card {
				margin-right:20px;
				margin-bottom:20px;
			}
		}

		.th-slider {
			@include min-mobile {
				padding-left: 0;
				padding-right: 0;
				flex-direction: column;
			}
		}

		.wrapper-map {
			height: 100vh;
			flex-grow: 1;

			.maps {
				height: 100vh;
			}

			@include mobile {
				position: relative;
				height: calc(100% - 200px); // 200 = hauteur du wrapper-cards sur mobile
				.maps {
					height: 100vh;
				}
			}
		}

		#pagination {
			position:absolute;
			background-color:white;
			bottom:0;
			padding:20px 40px;
			z-index:10;
			@include shadow();
			margin:0;
			left:0;
			width:100%;
			box-sizing: border-box;
			right:0;

			@include mobile {
				display:none;
			}

			span {
				border:none;
				font-weight:500;
			}
		}
	}

	.nav-buttons {
		@include min-mobile {
			display:none;
		}
	}


}




// Overlay - Recherche
// ==========================================================================
.overlay-search{
	max-width: 100%;

	.btn-close{
		position: absolute;
		top:0;
		right:0;
		@include min-tablet-portrait {
			right: -58px;
			top: 5px;
		}
	}

	.search-header{
		padding: 48px 0;
		@include shadow();
		@include mobile{
			padding: 25px 0;
		}

		.container-form {
			display: flex;
			align-items:center;
			justify-content: space-between;
		}

		form {
			width:100%;
			display: flex;
			align-items:center;
		}

		button[type="submit"]{
			@include tablet-portrait {
				margin-right: 50px;
			}
			&:after {
				margin-left:0;
				content:"";
			}
		}

		input{
			@extend %appearance;
			font-size: 20px;
			line-height: 48px;
			font-family:$f_txt;
			color: $c_txt;
			padding: 0 55px 0 15px;
			width: 100%;
			box-sizing: border-box;
			@include mobile{
				font-size: 16px;
				line-height: 24px;
				padding: 0 50px 0 10px;
			}
			&::placeholder{
				color: rgba($c_txt, 0.30);
			}
		}

		.nbresults_founded {
			display:inline-flex;
			margin-right:10px;
			font-size:16px;
			line-height:24px;

			.nbresults_value {
				margin-right:3px;
			}

			&.empty {
				display:none;
			}

		}

		.pagination {
			margin-top:0;
		}
	}

	.listing-container{
		position: relative;
		overflow: auto;
		max-height:calc(100% - 120px);
		@include mobile{
			max-height:calc(100% - 60px);
		}

		.search-results{
			position: relative;
			padding:30px 0;
			margin-left: -20px;
			margin-right: -20px;
			min-height: calc(100vh - 144px);

			@include tablet-portrait{
				margin-left: -10px;
				margin-right: -10px;
			}
			@include mobile{
				padding:10px 0;
				margin-left: 0;
				margin-right: 0;
			}
			&:after{
				content: '';
				display: block;
				clear: both;
			}
			.card{
				width:calc(33.3333% - 40px);
				margin:10px 20px;
				float: left;
				&:nth-child(3n+1){
					clear: left;
				}


				@include tablet-portrait{
					width:calc(33.3333% - 20px);
					margin:10px 10px;
				}
				@media screen and (max-width: 800px) {
					width:calc(50% - 20px);
					&:nth-child(3n+1){
						clear: none;
					}
					&:nth-child(odd){
						clear: left;
					}
				}
				@include mobile{
					width: 100%;
					margin:5px 0;
					@include card-horizontale();

					.caption{
						background-color: transparent;
					}
				}
			}

			>p, .loading-text p{
				display: block;
				clear: both;
				padding-top: 40px;
				text-align: center;
				font-weight: 700;
				font-size: 18px;
				line-height: 22px;
			}


			p[role="alert"] {
				position: absolute;
				top: 50%;
				left: 0;
				transform: translateY(-50%);
				width: 100%;
				display:flex;
				align-items:center;
				text-align:left;
				@include ft($f_hx, calculateRem(70px), 300, rgba($c_txt, 0.30), calculateRem(65px));


				i {
					font-size:122px;
					margin-right:56px;
					color:rgba($c_txt, 0.30);
				}

				@include tablet-portrait {
					font-size:calculateRem(50px);
					line-height:calculateRem(50px);
				}

				@include mobile {
					font-size:calculateRem(24px);
					line-height:calculateRem(24px);
					i {
						font-size:70px;
						margin-right:20px;
					}
				}
			}

		}

		#pagination{
			display: block;
			margin-top: 40px;
			margin-bottom: 40px;
			@include mobile{
				margin-bottom: 20px;
			}
			.resultats,.pagination-number,.--prev{
				display: none;
			}

			.--next{
				background-color: transparent;
				display: block;
				margin: 0 auto;
				text-align: center;
				@include mobile{
					width: 100%;
				}
				&:before{
					content: '\e90a';
					color: $c_txt;
					font-size: 40px;
					@include mobile{
						font-size: 26px;
					}
				}

				@include can-hover {
					transform: translate3d(0, 5px, 0);
				}
			}
		}
	}
}


// Overlay - focus SIT
// ==========================================================================
.overlay-focus-sit {
	.barre {
		position: sticky;
		bottom: 0;
		left: 0;
		right: 0;
		padding-right: 40px;
		display: flex;
		align-items: center;
		min-height: 100px;
		background-color: $c_white;
		box-shadow: 0 -30px 60px rgba($c_dark, 0.10);

		@include mobile {
			padding-right: 20px;
			min-height: 50px;
		}

		[data-content] {
			position: relative;
			width: 60px;
			height: 60px;
			border: 1px solid $c_sub;
			margin-right: 10px;
			display: flex;
			justify-content: center;
			align-items: center;

			@include mobile {
				width: 30px;
				height: 30px;
			}

			&:before {
				content: "";
				@include icomoon();
				display: block;
				color: $c_dark;
				font-size: 20px;

				@include mobile {
					font-size: 12px;
				}
			}

			&:hover, &:focus {
				body:not(.no-hover) & {
					.tooltiptext {
						visibility: visible;
					}
				}
			}

			&[data-content="telephone"] {
				&:before {
					content: "\e901";
				}
			}

			&[data-content="email"] {
				&:before {
					content: "\e900";
				}
			}

			&[data-content="site"] {
				&:before {
					content: "\e902";
				}
			}
		}

		.lien {
			max-width: 248px;
			padding: 42px 70px 42px 80px;
			font-size: 16px;
			white-space: nowrap;
			margin-right: auto;

			@include tablet-portrait {
				padding: 42px 40px 42px 40px;
			}
			@include mobile {
				font-size: 12px;
				padding: 22px 20px 22px 20px;
			}
		}

		.btn, .btn-primary, .btn {
			max-width: 142px;

			@include mobile {
				position: absolute;
				bottom: 56px;
				width: 100%;
				max-width: none;
				height: auto;
				font-size: 12px;
			}
		}
	}
}

// Overlay - Filtres
// ==========================================================================
.overlay-filtres {

	@include min-desktop {
		width:50%;
		max-width:none;
	}

	.bloc-form {
		height: 100%;

		form {
			height: 100%;

			.selectric-wrapper {
				max-width: 100%;
			}

			legend {
				display:block !important;
			}

			/* Facette Select */
			.select {
				.selectric-open {
					z-index: 100000;
				}
			}
		}

		.classement {
			margin-bottom:10px;
			color:rgba($c_txt, 0.60);

			&:last-child {
				margin-bottom:15px;
			}

			.classement-chk {
				margin-top:5px;
			}


			.title-group {
				font-weight:300;
				color:rgba($c_txt, 0.60);

				.total {
					display:none;
				}
			}

			[class^="icon-"], [class*=" icon-"] {
				&:before {
					font-size:calculateRem(12px);
				}
			}
		}

		fieldset {
			padding-bottom: 40px;
			margin-bottom: 40px;
			border-bottom: 1px solid rgba($c_dark, 0.10);

			@include mobile {
				padding-bottom: 20px;
				margin-bottom: 20px;
			}

			&:last-of-type {
				margin-bottom: 0;
				border-bottom: none;
			}
		}
	}


	//facette date
	.group-date{
		position: relative;

		.wrapper-date{
			label{
				font-family:$f_txt;
				font-size: 14px;
				line-height: 18px;
				color: $c_main;
				margin-bottom: 10px;
			}
			input{
				font-size: 14px;
				line-height: 20px;
				padding:14px 40px 14px 20px;
				border-color: $c_main;
			}
			&:before{
				top:auto;
				bottom: 18px;
			}
		}

		.date-picker-wrapper {
			position: absolute;
			left: 0;
			top: 80px;
			margin-right: 0;
			box-shadow: 5px 5px 20px rgba($c_dark,0.2);
			padding: 0 20px 20px;


			// règles pour forcer la largeur à rentrer dans l'overlay filtres
			.gap {
				visibility: hidden !important;
			}

			.month-wrapper {
				width: 660px !important;
				display: flex;
				.time{
					display: none;
				}
			}

			.month-wrapper table {
				width: calc(50% - 15px);
			}

			.month-wrapper table .day {
				padding: 8px;
			}
		}
	}
}

// Overlay - Auteur
// ==========================================================================
.overlay-auteur {
	.scroller {
		padding: 80px 40px 80px 80px;

		@media screen and (max-width: 1270px) {
			padding: 80px 40px 40px 40px;
		}

		@include mobile {
			padding: 40px 20px 20px 20px;
		}

		.container-auteur {
			display: flex;
			align-items: center;

			@include mobile {
				flex-direction: column;
				align-items: flex-start;
			}

			.author {
				@include ft($f_txt, $s_h3, 700, $c_txt, 0.8);
			}

			.surtitre {
				font-size: 12px;
				line-height: 1;
				margin-top: 8px;
			}

			figure {
				flex-shrink: 0;

				& + .text-wrapper {
					margin-left: 20px;

					@include mobile {
						margin-left: 0;
						margin-top: 20px;
					}
				}
			}

			.social-links {
				a {
					width: 40px;
					height: 40px;
				}
			}

			& + p {
				margin-top: 16px;
			}
		}

		p {
			@include ft($f_txt, $s_link, 300, $c_txt, 1.5);
		}

		.th-slider-wrapper {
			margin-top: 50px;
			margin-right: -40px;

			.title-slider {
				display: block;
				@include ft($f_txt, $s_small_title, 700, $c_txt, 1);
				margin-bottom: 15px;
			}

			.nav-buttons {
				margin-bottom: 20px;
			}

			.card {
				width: 320px;
				margin-right: 20px;
			}

			&.scroll-mode-parent {
				@media screen and (max-width: 1270px) {
					margin-left: -40px;
					margin-right: -40px;
					width: calc(100% + 80px);
				}
				@include mobile {
					margin-left: -20px;
					margin-right: -20px;
					width: calc(100% + 40px);
				}

				.title-slider, .nav-buttons {
					@media screen and (max-width: 1270px) {
						margin-left: 40px;
					}
					@include mobile {
						margin-left: 20px;
					}
				}
			}
		}
	}
}

// Overlay - Shadow
// ==========================================================================
.shadow-overlay {
	position: fixed;
	top: 0;
	left: 100%;
	right: 0;
	bottom: 0;
	width: 100%;
	height: 100%;
	background: rgba($c_bg, 0.6);
	opacity: 0;
	z-index: z-index('overlay-shadow');
	display: block;
	overflow: hidden;
	transition: opacity 400ms cubic-bezier(0.86, 0, 0.07, 1);

	&.open {
		opacity: 1;
		left: 0;
	}
}

.overlay-agenda{

	.container {
		height: 100%;
	}

	.scroller {
		padding-bottom: 140px;
	}

	.loader {
		position: absolute;
		top: 50%;
		left: 50%;
		margin-top: -12px;
		margin-left: -12px;
	}

	.surtitre{
		margin-top: 0;
	}
	h1{
		color: $c_bg;
		font-size: 50px;
		line-height: 50px;
		margin-bottom: 5px;
		@include mobile {
			font-size: 35px;
			line-height: 35px;
		}
	}

	.dates{
		margin-bottom: 12px;
		>div{
			display: flex;
			flex-wrap: wrap;
		}
		.date,.heure{
			font-size: 20px;
			line-height: 30px;
		}
		.date{
			font-weight: 700;
		}
		.heure{
			margin-left: 7px;
		}
	}
	.location{

	}


	.a-propos{
		margin-top: 32px;

		h2{
			font-size: 40px;
			line-height: 50px;
			margin-bottom: 12px;
			@include mobile{
				font-size: 24px;
				line-height: 24px;
			}
		}

		ul{
			li{
				font-weight: 400;
				padding-left: 0;
				display: flex;
				&:before{
					content:none;
				}
				span{
					flex-shrink: 0;
					color: $c_main;
					margin-right: 15px;
				}
			}
		}

		figure{
			width:100%;
			height:auto;
			background-color: transparent;
			img{
				width: 100%;
				height:auto;
				border-radius: 50px 50px 5px 50px;
				@include mobile{
					border-radius: 30px 30px 5px 30px;
				}
			}
		}

	}

	.bottom-part{
		&:before{
			content: '';
			position: absolute;
			bottom: 100%;
			left: 0;
			right: 0;
			height: 40px;
			pointer-events: none;
			background: rgb(255,255,255);
			background: linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%);
		}
	}
}