// Les styles des facettes sont appliqués singulièrement au niveau de chaque facette,
// afin de pouvoir les avoir bien affichés dans l'overlay filtres mais aussi dehors

// Styles appliqués aux labels et des fois à la balise legend dans les fieldset
.title-facette {
	@include ft($f_txt, 18px, 600, $c_txt, 26px);
	position: relative;
	display: block;
	margin: 0 0 30px 0;
	padding: 0;

	@include mobile {
		font-size: calculateRem(16px);
		line-height: calculateRem(18px);
		margin: 0 0 15px 0;
	}
}

// Styles pour les facettes checkbox
.facette-checkbox {
	position: relative;
	display: flex;
	flex-direction: row-reverse;
	justify-content: flex-end;
	align-items: center;
	cursor: pointer;
	margin-bottom: 10px;

	input {
		display: none !important;
	}

	i {
		color:$c_main;
	}

	&:last-of-type {
		margin-bottom: 0;
	}
}

// Classe utilisée pour mettre en forme les select fields gérés par la librairie Selectric
.group-select {
	.selectric-wrapper {
		max-width: 280px;

		.selectric-items {
			.selectric-scroll {
				ul {
					li {
						max-width: none;
					}
				}
			}
		}

		.selectric {
			min-height: 60px;
			border: 1px solid rgba($c_dark, 0.25);
			width: 100%;
			display: flex;
			align-items: center;

			.label {
				height: auto;
				padding: 0;

				&:after {
					right: 20px;
				}
			}
		}
	}
}


// Styles pour les facettes avec les switch
.facette-switch {
	display: flex;
	align-items: center;
	justify-content: space-between;
	cursor: pointer;
	margin-bottom: 0;

	input {
		display: none !important;

		&:checked {
			& + span {
				background-color: $c_main;
				border:$c_main;

				&:before {
					transform: translateX(26px);
					background-color: $c_white;
				}
			}
		}
	}
	span {
		position: relative;
		display: inline-block;
		width: 48px;
		height: 24px;
		border:1px solid rgba($c_bg, 0.29);
		background-color: $c_white;
		border-radius: 50px;
		flex-shrink: 0;
		@include addTransition();

		&:before {
			content: "";
			display: block;
			width: 12px;
			height: 12px;
			background-color: $c_main;
			border-radius: 50%;
			position: absolute;
			top: 6px;
			left: 5px;
			@include addTransition();
		}
	}
}

.group-date {
	position: relative;
	display: flex;
	@include mobile {
		flex-direction: column;
	}

	&:before{
		content: '\e923';
		font-family:'icomoon';
		position: absolute;
		top: 18px;
		left: 35px;
		font-size: 16px;
		z-index: 10;
		color: white;
		@include mobile{
			left: 18px;
		}
	}
	>div.wrap {
		padding-left: 20px;
		position: relative;
		width: auto;
		flex-basis: auto;
		flex-shrink: 0;
		display: flex;
		align-items:center;

		&:first-of-type {
			padding-left: 70px;
			@extend %bgLtR;
			border-radius: 60px 0 0 60px;
			label.date-label, input:not(.fakeinput){
				color: white;
			}
			@include mobile{
				border-radius: 30px 30px 0 0;
				padding-left: 50px;
			}
		}
		&:not(:first-of-type){
			border: 1px solid $c_bg;
			border-radius: 0 60px 60px 0;
			border-left: 0;
			@include mobile{
				border-top: 0;
				border-left: 1px solid $c_bg;
				border-radius: 0 0 30px 30px;
			}
		}

		label.date-label {
			display: inline-block;
			margin-bottom: 0;
			position: relative;
			top: 0;
			left: 0;
			flex-shrink: 0;
			font-size: 16px;
			line-height: 20px;
		}

	}

	input:not(.fakeinput) {
		@include ft($f_txt, 16px, 700, $c_txt, 20px);
		padding: 15px 35px 15px 5px;
		border: none;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		text-transform: none;
		-webkit-appearance: none;
		resize: none;
		-webkit-box-shadow: none;
		outline: none;
		margin: 0;
		box-shadow: none;
		background: none;
		text-align: left;
		min-height: 0!important;
		width: 135px;

		@include mobile {
			width: 100%;
			min-height: 56px;
		}

		&::-webkit-date-and-time-value {
			text-align: left;
		}
	}

	.fakeinput {
		opacity: 0;
		position: absolute;
		left: 0;
		//width: 390px;
		margin-top: 20px;
		height: 60px;
		padding: 0;
	}
}


