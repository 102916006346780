.strate-info {
	position: absolute;
	top: 0;
	left: 0;
	display: flex;
	align-items: center;
	width: 100%;
	height: 30px;
	padding-left: 40px;
	z-index: z-index('strate-info');
	animation: 1s ease-in-out fromTopToBottom;
	@extend %bgLtR;
	@include mobile {
		padding-left: 20px;
	}

	&:after {
		content: "";
		display: block;
		width: 30%;
		height: 100%;
		background: linear-gradient(90deg, rgba($c_sub, 0), rgba($c_sub, 1));
		position: absolute;
		right: 0;
		top: 0;
		z-index: 0;
		pointer-events: none;
		@include mobile {
			width: 80%;
		}
	}

	[class^="icon-"], [class*=" icon-"] {
		color: $c_white;
		font-size: 9px;
		border: 1px solid $c_white;
		border-radius: 90px;
		padding: 5px 10px;
		z-index: 1;

		& + * {
			margin-left: 20px;
			@include mobile {
				margin-left: 10px;
			}
		}
	}

	.scroll-container {
		overflow-x: auto;
		scroll-snap-type: x mandatory;
		flex: 1;
		white-space: nowrap;
		-ms-overflow-style: none; /* IE and Edge */
		scrollbar-width: none; /* Firefox */
		&::-webkit-scrollbar {
			display: none;
		}

		p {
			@include ft($f_txt, 11px, 400, $c_white, 1.18);
		}
	}

	.btn-strate-info {
		@include ft($f_txt, 10px, 700, $c_white, 1.3);
		@include letter-spacing(-13);
		text-transform: uppercase;
		background-color: $c_main;
		height: 100%;
		display: flex;
		align-items: center;
		padding-left: 20px;
		padding-right: 20px;
		border-radius: 90px 0 0 90px;
		white-space: nowrap;
		vertical-align: middle;
		z-index: 1;

		&:hover, &:focus {
			body:not(.no-hover) & {
				color: $c_white;
				background-color: $c_bg;
			}
		}
	}
}

@keyframes fromTopToBottom {
	0% {
		transform: translateY(-100%);
		opacity: 0;
	}
	100% {
		transform: translateY(0);
		opacity: 1;
	}
}