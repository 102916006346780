.meta-wrapper {
	display: flex;
	align-items: center;
	flex-wrap: wrap;
	margin: 0;

	.meta {
		@include ft($f_txt, $s_link_small, 400, $c_bg, 1.25);
		background-color: transparent;
	//	border: 1px solid $c_bg;
		padding: 4px 15px 7px;
		max-height: 30px;
		margin-bottom: 5px;
		border-radius: 30px;

		&:not(:last-of-type) {
			margin-right: 5px;
		}

		&:before {
			display: none;
		}

		[class^="icon-"], [class*=" icon-"] {
			font-size: 14px;
			margin-right:10px;
		}

		&.with-icon {
			padding: 7px;
		}

		&.iti-difficile {
			position: relative;
			padding-left: 22px;

			&:before {
				content: "";
				display: block;
				width: 8px;
				height: 8px;
				border-radius: 50%;
				background-color: $c_dark;
				position: absolute;
				top: 50%;
				transform: translateY(-50%);
				left: 10px;
			}
		}

		.hide-on-mobile {
			@include mobile {
				display: none;
			}
		}
	}
}