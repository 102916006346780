// Styles pour l'affichage des cards en format horizontale
// ==========================================================================
.cards-horizontales {
	.card {
		@include card-horizontale();

		.image {
			.info-desktop {
				bottom: auto;
				top: 10px;
				display: none;
			}

			.info-mobile {
				display: block;
			}
		}
	}

	// On fait un exception pour le bloc agenda :
	// dans ce bloc vu que les cards ont des images plus grandes on peut se permettre d'avoir un font-size plus grand
	// et aussi d'afficher les metas à côté. Dans le reste du site les cards sont trop petites, donc il faut cacher les metas
	// et réduire le font size des dates

	&:not(.bloc-agenda) {
		.date-event {
			.day, .month {
				font-size: 14px;
				line-height: 1;
			}
		}
	}
}

// Card - Default Styles
// ==========================================================================
.card {
	position: relative;
	display: block;
	width: 100%;

	@include mobile {
		@include card-horizontale();
	}

	&:last-of-type {
		@include mobile {
			margin-bottom: 0;
		}
	}

	&:hover, &:focus {
		body:not(.no-hover) & {
			.title-card {
				color: $c_sub;
			}
		}
	}

	.credits {
		display: none;
	}

	.image {
		position: relative;
		margin-bottom: 15px;
		flex-shrink: 0;
		border-radius: 5px 5px 5px 50px;
		@include aspect-ratio(360, 263);
		@include addTransition();

		@include mobile {
		//	width: 90px;
		//	height: 90px;
			margin-right: 9px;
		}

		> figure, > .no-photo {
			@extend %center;
		}

		> figure {
			background: transparent;

			figcaption {
				display: none;
			}
		}

		.no-photo{
			@extend %bgTtB;
		}

		.info-desktop {
			font-size: 12px;
			line-height: 1.25;
			font-weight: 300;
			color: $c_white;
			background-color: transparent;
			padding: 4px 9px;
			position: absolute;
			bottom: 10px;
			left: 10px;
			z-index: 2;

			strong{
				display: block;
			}

			&.all-strong {
				font-weight: 700;
			}
		}

		.info-mobile {
			font-size: 10px;
			line-height: 1.3;
			font-weight: 700;
			color: $c_white;
			background-color: $c_main;
			position: absolute;
			top: 0;
			left: 0;
			padding: 3px 5px;
			z-index: 1;

			@include min-mobile {
				display: none;
			}
		}

		.btn-suppr {
			display:none;
		}

		.btn-favoris {
			position: absolute;
			top: 0;
			right: 0;
			padding: 30px;

			&:after {
				content: "";
				display: block;
				width: 220px;
				height: 220px;
				background-image: url("../images/deco/radial-gradient.svg");
				position: absolute;
				bottom: 0;
				right: 0;
				z-index: -1;
				pointer-events: none;

				@include mobile {
					width: 90px;
					height: 90px;
				}
			}
		}

		[class^="icon-"]:not(.btn-favoris):not(.btn-suppr), [class*=" icon-"]:not(.btn-favoris):not(.btn-suppr)  {
			width: 24px;
			height: 24px;
			background-color: $c_main;
			font-size: 12px;
			color: $c_white;
			position: absolute;
			top: 0;
			left: 0;

			&:before {
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
			}
		}
	}

	.tarif{
		display: inline-block;
		font-size: 12px;
		line-height: 14px;
		text-transform: uppercase;
		color: $c_bg;
		border:1px solid $c_bg;
		border-radius:50px;
		font-weight: 600;
		padding:3px 8px;
		margin-bottom: 5px;
	}

	.container {
		display: flex;
		flex-wrap: wrap;
		align-items:center;
		@include ft($f_txt, 12px, 300, $c_txt, 1.25);

		.classement {
			display: inline-block;
			vertical-align: top;
			margin-right: 5px;
			color:$c_sub;

			&:first-of-type {
				margin-left: 4px;
			}

			span {
				font-size: 12px;
				line-height: 1;

				@include mobile {
					font-size: 10px;
				}

				&.icon-ico-cle, &.icon-ico-epis {
					position: relative;
					top: 1px;
				}

				&.icon-ico-stars {
					& + .icon-ico-epis, & + .icon-ico-cle {
						margin-left: 5px;
					}
				}

				&.icon-ico-epis {
					@include mobile {
						top: 0;
						margin-left: -3px;
					}
				}
			}

			&.classement-epis {
				@include mobile {
					margin-left: 2px;
				}
			}
		}

		.cat {
			display: inline-block;
			font-size: 20px;
			line-height: 25px;
			font-weight: 700;
			color: $c_bg;
			@include mobile{
				font-size: 16px;
				line-height: 25px;
			}
		}
		.post-cat{
			display: inline-block;
			font-size: 16px;
			line-height: 26px;
			font-weight: 400;
			color: $c_sub;
		}
	}

	.caption {
		width: 100%;
		padding-left: 30px;
		@include mobile{
			padding-left: 10px;
		}
		& > * {
			&:last-child {
				margin-bottom: 0;
			}
		}

		.title-card {
			@include ft($f_hx, 34px, 400, $c_txt, 1);
			display: block;
			margin: 0 0 10px 0;
			@include addTransition();

			@include mobile {
				font-size: 24px;
				line-height: 28px;
				margin: 0 0 6px 0;
			}
		}
	}

	.meta-wrapper {
		justify-content: flex-start;
		margin-right:10px;

		.meta {
			@include ft($f_txt, 16px, 500, $c_sub, 16px);
			border:none;
			padding:0;
			max-height:initial;

			&.with-icon {
				padding: 4px;
			}
		}

		&.meta-heure {
			background-color: $c_main;
			font-weight: 700;
		}

		& + .location {
			@include min-mobile {
				margin-top: 7px;
			}
		}
	}

	.complementary-info {
		display: none;
	}

	.published{
		position: relative;
		font-size: 16px;
		line-height: 25px;
		padding-left: 18px;
		color: $c_txt;
		@include mobile{
			font-size: 14px;
		}
		&:before{
			content: '\e912';
			font-family:'icomoon';
			font-size: 12px;
			line-height: 1;
			top: 7px;
			left: 0;
			position: absolute;
			color: $c_txt;
		}
	}


	&.agenda{
		box-shadow:none;

		.image{
			.dates{
				position: absolute;
				top: 0;
				bottom: 0;
				right: 0;
				left: 0;
				z-index: 4;
				display: flex;
				justify-content:center;
				align-items: flex-end;
				flex-flow: column;
				padding: 20px;
				margin: 0 auto;
				.finished, .jour span{
					background-color: white;
					text-transform: uppercase;
					font-size: 18px;
					line-height: 20px;
					color: $c_txt;
					font-weight: 700;
					padding: 0 5px 2px;
					border-radius:5px;
					clear: both;
					float: right;
					&:not(:last-child){
						margin-bottom: 5px;
					}
					@include mobile{
						font-size: 16px;
						line-height: 18px;
					}
				}
				.heure{
					display: block;
					color: white;
					text-transform: uppercase;
					font-size: 16px;
					line-height: 18px;
					font-weight: 400;
					&:not(:last-child){
						margin-bottom: 5px;
					}
					@include mobile{
						font-size: 14px;
						line-height: 16px;
					}
				}
			}
		}
	}
}
