.ancres {

	// TOUCH DEVICE
	.no-hover & {
		@include desktop {
			padding: 0;
		}
		@include mobile{
			width: 100%;
			margin-left: 0;
			margin-right: 0;
		}
		.th-slider{
			@include desktop{
				margin: 0;
			}
		}
	}

	& ~ footer {
		margin-bottom: 60px;
	}

	&:before {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		bottom: 0;
		width: 50px;
		background: none;
	}

	&:after {
		content: '';
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		width: 50px;
		background: none;
		@include mobile {
			content: none;
		}
	}

	// Lorsqu'on est Stick
	.ancre-sticky & {
		@extend %ancre-scrolled;
	}

	.container{
		.track-scroll-wrapper{
			position: relative;
			margin-left: 0;
			flex-shrink: 1;
			min-width: 0;
			overflow: hidden;
			margin-right: 20px;
			.nav-buttons{
				position: absolute;
				top: 5px;
				left: 0;
				right: 0;
				@include mobile{
					top: 0;
				}
				button{
					position: absolute;
					top: 0;
					border:none;
					&[disabled]{
						opacity: 0;
						pointer-events: none;
					}
					&:first-child{
						left: 20px;
						@include mobile{
							left: 10px;
						}
					}
					&:last-child{
						right: 0;
					}

					&:hover, &:focus, &:active {
						background:none;
						opacity:0.7;

						&:before, &:after {
							color:$c_txt;
						}
					}
				}
			}
		}
		.track-scroll {
			display: flex;
			align-items: center;
			white-space: nowrap;
			padding: 0;
			min-width: 100%;
			padding-left: 20px;
			padding-right: 20px;

			// TOUCH DEVICE
			.no-hover & {
				@include desktop {
					overflow: hidden;
					overflow-x: auto;
					-webkit-overflow-scrolling: touch;
					scroll-snap-type: both mandatory;
					scroll-padding: $border-gutter;
					padding-left: $border-gutter;
					padding-right: $border-gutter;
					//margin: 0 $border-gutter;
					> * {
						scroll-snap-align: start;
					}

					> *:last-child {
						scroll-snap-align: end;
						padding-right: 40px;
					}
				}
				@include tablet_landscape {
					scroll-padding: 40px;
					padding-left: 20px;
					padding-right: 20px;
				}
				@include mobile {
					scroll-padding: 20px;
					padding-left: 10px;
					padding-right: 10px;
				}
			}

			> a {
				@include ft($f_txt, 16px, 400, rgba($c_txt, .3), 20px);
				position: relative;
				padding: 15px 20px 15px 20px;
				display: block;
				width: auto;
				margin: 0;
				@include addTransition();
				@include mobile {
					font-size: calculateRem(12px);
					padding: 8px 10px 12px 10px;
				}
				&:hover, &:focus {
					body:not(.no-hover) & {
						color: $c_txt;
						font-weight: 400;
					}
					body.ancre-sticky:not(.no-hover) & {
						color: $c_txt;
					}
				}
				&.active {
					font-weight: 400;
					color: $c_txt;
				}
			}
		}

		.barre-sticky__inner{
			position: relative;
			display: flex;
			justify-content: center;
			align-items:center;
			margin-left:auto;
			margin-right: 0;
			flex-shrink: 0;
		}
	}


	&.--compact{
		left: auto;
		width:auto;
		display: flex;
		position: fixed;

		&:before, &:after{
			content:none;
		}

		.barre-sticky__inner{
			background-color: white;
			width: auto;
			display: flex;
			justify-content: flex-end;
			align-items:center;
			margin-left: auto;
			padding:25px 20px;
			margin-right: 50px;
			box-shadow: 0 10px 30px rgba(0,0,0,.1);
			@include tablet_portrait{
				margin-right: 40px;
			}
			@include mobile{
				padding: 15px;
				margin-right: 20px;
			}
		}
	}

}


%ancre-scrolled{
	background: $c_white;
	&:before, &:after {
		content: '';
		position: absolute;
		left: 0;
		right: 0;
		top: 0;
		width: 100%;
		height: 1px;
		display: block;
		background: rgba(#AAA9A9, 0.2);
	}
	&:after {
		top: auto;
		bottom: 0;
	}

	.social-share {
		.toggle {
			color: $c_txt;
		}
	}
	.track-scroll {
		> a {
			color: $c_txt;
			&:after {
				background: $c_main;
			}
		}
	}
}