// Custom Windows Map
// ==========================================================================
.custom-popup-thuria {
  width: 410px;
  margin-bottom: 50px;
  @include mobile {
    width: 300px;
  }

  .leaflet-popup-wrapper {
    padding: 0;
    border-radius: 0 0 0 50px;
    box-shadow: 0 10px 20px rgba($c_dark, 0.1);

    .leaflet-popup-content {
      margin: 0;
      width: 100% !important;

      .card-map {
        position: relative;
        top: auto;
        right: auto;
        transform: none;
        box-shadow: none;
        display: block;
      }

      .card-map-mobile {
        display: none;
        @include mobile {
          display: block;
        }
      }
    }
  }

  .leaflet-popup-tip-container {
    width: 20px;
    height: 20px;
    transform: rotate(45deg);
    position: absolute;
    left: 50%;
    margin-left: -10px;
    bottom: -10px;
    overflow: hidden;
    pointer-events: none;

    .leaflet-popup-tip {
      padding: 0;
      box-shadow: none;
      margin: 0 auto;
      width: 26px;
      height: 26px;
      border-radius: 0;
      transform: none;
    }
  }

  a.leaflet-popup-close-button {
    position: absolute;
    top: -15px;
    right: -15px;
    background: $c_bg;
    color: $c_white;
    padding: 0;
    border-radius: 50%;
    font-size: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    //	line-height: 27px;

    @include mobile {
      width: 30px;
      font-size: 10px;
      height: 30px;
    }

    &:after {
      content: "\e928";
      @include icomoon();
      position: absolute;
      background-color: inherit;
      transform: translate(-50%, -50%);
      top: 50%;
      left: 50%;
    }

    &:hover {
      body:not(.no-hover) & {
        cursor: pointer;
        background: $c_greydark;
        color: $c_white;
      }
    }
  }

  .card {
    @include card-horizontale();
    margin-bottom: 0 !important;
    min-height: 140px;

    .image {
      min-height: 140px;
      width: 160px;
    }

    @include mobile {
      min-height: 100px;

      .image {
        height: 100px;
        width: 90px;

        .btn-favoris {
          padding: 10px;
        }
      }

      .caption {
        .title-card {
          font-size: 20px;
          text-overflow: ellipsis;
          line-height: 1;
          white-space: nowrap;
          overflow: hidden;
          max-width: 159px;
        }

        .location {
          font-size: 12px;
        }
      }
    }


    &.agenda {
      .image {
        min-height: 140px;
      }
    }

  }
}