

// Génére les classes de colonne pour chaque breakpoint
// col-1@mobile, col-2@mobile... col-6@mobile
// col-1@t-small, col-3@t-small... col-6t-small
@mixin basic-grid-set-columns($bp-name:null) {
    $prefix: get-breakpoint-prefix($bp-name);
    $col-max: 6;      // Grille générée jusqu'à 6 colonnes

    @for $col from 1 through $col-max {
        @if $bp-name == null {
            &.col-#{$col} {
                grid-template-columns: repeat($col, 1fr);
            }
        } @else {
            &.col-#{$col}\@#{$prefix} {
                grid-template-columns: repeat($col, 1fr);
            }
        }
    }
}

// Système de grille responsive avec colonnes de même largeur
// On définit le nombre de colonnes en ajoutant les classes directement sur le container, pas sur les enfants
.basic-grid {
    --gap: var(--grid-gap);        // espacement horizontal / vertical par défaut entre les enfants de la grille
    display: grid;
    gap: var(--gap);

    // par défaut, colonnes "stackées" en 1 colonne en-dessous de 320px 
    grid-template-columns: 1fr; 

    // Si pas de préfixe, génére les classes de colonnes : col-2, col-3... 
    @include basic-grid-set-columns();

    // Pour chaque breakpoint, génére les classes de colonnes : col-2@mobile, col-3@mobile...
    // @media min-width: 320px -> mobile
    @include bp-up(mobile-small) {
        @include basic-grid-set-columns('mobile');
    }
    // @media min-width: 599px -> tablet-small
    @include bp-up(mobile) {
        @include basic-grid-set-columns('tablet-small');
    }
    // @media min-width: 768px -> tablet-port
    @include bp-up(tablet-small) {
        @include basic-grid-set-columns('tablet-portrait');
    }
    // @media min-width: 980px -> tablet-land
    @include bp-up(tablet-portrait) {
        @include basic-grid-set-columns('tablet-landscape');
    }
    // @media min-width: 1024px -> desktop
    @include bp-up(tablet-landscape) {
        @include basic-grid-set-columns('desktop');
    }
    // @media min-width: 1280px -> wide
    @include bp-up(desktop) {
        @include basic-grid-set-columns('wide');
    }
    // TODO
    // @media min-width: 1600px -> x wide
    @include bp-up(wide) {
        @include basic-grid-set-columns('x-wide');
    }

    // --modifiers
    &.--no-gap { 
        --gap: 0; 
    }

    // Gouttière entre les enfants plus petite
    &.--small-gap {
        --gap: var(--grid-gap-small);
    }

    // Rangées de même hauteur
    &.--auto-rows { 
        grid-auto-rows: 1fr; 
    }

    // Alignement vertical des enfants dans leur cellule (par défaut stretch)
    &.--align-top {    align-items: start;   }
    &.--align-center { align-items: center;  }
    &.--align-bottom { align-items: end; }

    // Alignement horizontal des enfants dans leur cellule (par défaut stretch)
    &.--justify-left {    justify-items: start;   }
    &.--justify-center { justify-items: center;  }
    &.--justify-end { justify-items: end; }
}
