

.grid-12 {
    --gap: var(--grid-gap);        // espacement horizontal / vertical par défaut entre les enfants de la grille
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    gap: var(--gap);

    // par défaut, colonnes "stackées" en 1 colonne
    > * {
        grid-column: span 12;
    }

    .col-4 {
        grid-column: span 4;    // col 1/3
    }

    .col-6 {
        grid-column: span 6;    // col 1/2
    }

    .col-8 {
        grid-column: span 8;    // col 2/3
    }

    .item-grid {
        display: flex;
        flex-direction: column;
        text-align: center;
        padding: 8px;
        @include bd(green,1);
    }
}