.strate-footer-logos-slider {
	.wrapper{
		.container{
			display: flex;
			//justify-content: space-between;
			flex-wrap: wrap;
			margin-left: -10px;
			margin-right: -10px;
		}

		&:after{
			content: '';
			display: block;
			clear: both;
		}
		.item{
			float: left;
			display: block;
			box-sizing: border-box;
			margin: 0 10px 20px;
			background-color: transparent;
			figure{
				background-color: transparent;
			}
		}
	}
}