.strate-footer-widgets {
	padding-bottom: 80px;
	&:before{
		content: '';
		position: absolute;
		top: -40px;
		left: 0;
		bottom: 0;
		right: 0;
		background-color: $c_bg;
		z-index: -1;
	}
	@extend %patternBG-bottom;
	&:after{
		background: $c_bg;
	}

	.title {
		@include ft($f_txt, 20px, 600, white, 30px);

		& + * {
			margin-top: $small-margin;
		}
	}

	.widget-menu {
		a {
			display: inline-block;
			@include ft($f_txt, 20px, 600, white, 30px);
			padding: 7px 0;

			&:hover, &:focus {
				body:not(.no-hover) & {
					color: $c_sub;
				}
			}
		}

		// exemple de menu niv2
		>ul {
			>li:first-child a{
				padding-top: 0;
			}
			ul {
				padding-left: 20px;
				a {
					font-size: 16px;
				}
			}
		}

		.title{
			margin-top: 50px;
		}

		.brochures{
			position: relative;
			width: 108px;
			height: 121px;
			margin:0 auto;
			display: block;
			padding: 0;
			font-size: 0;
			margin-top: 35px;
			>*{
				position: absolute !important;
				border-radius:20px;
				overflow: hidden;
				.credits, figcaption{
					display: none;
				}
				figure{
					position: absolute;
					top: 0;
					bottom: 0;
					right: 0;
					left: 0;
					background-color: transparent;
					img{
						width: 100%;
						height:auto;
					}
				}
			}


			&__1{
				left: -78px;
				z-index: 2;
			}
			&__2{
				@include aspect-ratio(108, 121);
				width: 108px;
				top: 0;
				left: 50%;
				margin-left: -54px;
				z-index: 3;
			}
			&__3{
				right: -78px;
				z-index: 1;
			}

			&__1, &__3{
				@include aspect-ratio(142, 94);
				width: 142px;
				top: 13px;
			}
		}
	}

	.widget-contact {
        @include mobile{
            margin-bottom: 30px;
        }

		p {
			font-weight: 400;
			color: white;
		}

		.btn + .btn {
			margin-top: ($small-margin / 2);
		}

		.text-styles {
			margin-bottom: 20px;
		}

        .btn{
            @include tablet-portrait{
                margin-right: 40px;
            }
        }
	}
}
