// style commun à la barre sticky single sit et listing et page EDITO

.barre {
  position: fixed;
  left: 0;
  right: 40px;
  bottom: 0;
  width: auto;
  @include addTransition();
  z-index: z-index('barre-sticky');

  @include tablet-portrait {
    top:0;
    right: 0;
    bottom:auto;
    visibility:hidden;
    opacity:0;
    @include addTransition();
  }

  .is-scrolled & {
    visibility:visible;
    opacity:1;
    @include addTransition();
  }

  .container {
    display: flex;
    align-items: center;
    background-color: $c_white;
    border-radius: 0 50px 0 0;
    height: 100px;
    padding: 25.5px 40px;
    @include shadow();

    @include tablet-portrait {
      padding-left: 40px !important;
      padding-right: 40px !important;
      height: 80px;
      border-radius:0;
    }

    @include mobile {
      margin-top: 0;
      border-radius: 0;
      padding-left: 20px !important;
      padding-right: 20px !important;
      height: 80px;
    }
  }


  // buttons
  .btn-favoris {
    position: relative;
    display: block;
    color: $c_bg;
    padding: 20px 30px 20px 20px;

    @include mobile {
      padding: 20px 10px 20px 10px;
    }

    .tooltiptext {
      top: calc(-100% + 40px);
    }

    &:hover, &:focus {
      body:not(.no-hover) & {
        color: $c_sub;

        .tooltiptext {
          visibility: visible;
        }
      }
    }
  }


  .social-share {
    margin-left: 15px;
    @include mobile {
      display: none;
    }
  }

}