.strate-footer-social-wall {
	width: auto;

	@include mobile {
		width: 100%;
		overflow: hidden;
	}


	.bg-feuille {
		position:relative;
		&:before {
			@extend %feuille-quadrille-verte;
			bottom:55px;
			left:-180px;
			top:initial;

			@include tablet-portrait {
				bottom:20px;
			}
		}

        &.scroll-mode-parent {
            margin: 0;
            width: 100%;
            max-width: none;
        }
	}

	.wrapper.scroll-mode {
		margin: 0;
		width: 100%;

		.th-slider {
			overflow: auto;
			scroll-snap-type: x mandatory;
			width: calc(100%);
			padding-right: 40px;
			@include tablet-landscape {
				padding-left: 40px;
				padding-right: 40px;
				scroll-padding: 40px;
				&:after {
					content: '';
					min-width: 40px;
				}
			}
			@include mobile {
				padding-left: 20px;
				padding-right: 20px;
				scroll-padding: 20px;
				&:after {
					content: '';
					min-width: 20px;
				}
			}
		}
	}

	.text-wrapper {
		margin-bottom: 14px;

		.surtitre{
			color: $c_sub;
			font-weight: 600;
			font-size: 20px;
			line-height: 26px;
			@include mobile{
				font-size: 16px;
				line-height: 22px;
			}
		}
		.texte-en-avant {
			display: block;
			font-size: 55px;
			line-height: 45px;
			@include mobile{
				font-size: 35px;
				line-height: 28px;
			}
		}
	}

	.social-links {
		margin-top: 45px;
		margin-bottom: 40px;
		font-size:0;
		@include mobile{
			margin-top: 25px;
			margin-bottom: 20px;
		}

		a {
			width: 50px;
			height: 50px;
			display: flex;
			align-items: center;
			justify-content: center;
			border-radius:50px;
			margin-right: 15px;
			background-color: $c_main;
			color: white;
			border: 0;
			//margin-bottom: 15px;
			@include addTransition();
			@include mobile{
				margin-bottom: 10px;
				margin-right: 10px;
			}

			[class^="icon-"], [class*=" icon-"] {
				display: block;
				font-size: 17px;
				color: white;
				@include addTransition();
			}

			&:hover, &:focus {
				body:not(.no-hover) & {
					background-color: $c_bg;
					[class^="icon-"], [class*=" icon-"], .title {
						color: white;
					}
				}
			}
		}
	}

	.nav-buttons {
		position: absolute;
		bottom: 0;
		right: 0;
		height: auto;
		@include mobile {
			//position: static;
			display:none;
			//margin-bottom: 20px;
		}

		button {
			&:first-of-type {
				margin-right: 20px;
			}
		}
	}

	.slide {
		position: relative;
		width: 240px;
		height: 240px;
		margin-right: 14px;
		overflow: hidden;
		border-radius: 5px;
		@include mobile {
			width: 150px;
			height: 150px;
			margin-right: 9px;
		}

		&:before {
			content: "\e925";
			display: block;
			@include icomoon();
			font-size: 20px;
			color: $c_white;
			position: absolute;
			bottom: 40px;
			right: 40px;
			z-index: 1;
			@include addTransition();

			@include mobile{
				font-size: 13px;
				bottom: 20px;
				right: 20px;
			}
		}

		figure{
			background: none;
            height: 100%;
            img{
                height: 100%;
            }
		}

		&:hover, &:focus {
			body:not(.no-hover) & {
				&:before {
					color: $c_sub;
				}
			}
		}
	}
}
