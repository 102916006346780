.social-links {
	display: flex;
	margin: 0px;
	
	@include mobile {
		flex-wrap: wrap;
	}

	a {
		margin-right: 5px;
		cursor: alias;

		&:last-of-type {
			margin-right: 0;
		}

		&:before {
			@include icomoon();
			font-size: 15px;

			@include mobile {
				font-size: 12px;
			}
			@include mobile-small {
				font-size: 10px;
			}
		}

		&[data-icon="facebook"] {
			&:before {
				content: "\e927";
			}
		}

		&[data-icon="twitter"] {
			&:before {
				content: "\e926";
			}
		}

		&[data-icon="instagram"] {
			&:before {
				content: "\e925";
			}
		}

		&[data-icon="pinterest"] {
			&:before {
				content: "\e924";
			}
		}

		&[data-icon="youtube"] {
			&:before {
				content: "\e933";
			}
		}

		&[data-icon="tripadvisor"] {
			&:before {
				content: "\e948";
			}
		}


		&:hover, &:focus {
			body:not(.no-hover) & {
				background-color: $c_main;

				&:not([disabled]) {
					cursor: alias;
				}

				&:before {
					color: $c_white;
				}
			}
		}
	}
}