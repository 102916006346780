// ==========================================================================
.bloc-form {

	// FORMULAIRE FORMIDABLE
	// ==========================================================================
	form {
		position: relative;
		height: 100%;
		.frm_error {
			position: relative;
			display: block;
			margin-top: 5px;
			color: $c_red;
			font-size: 12px;
			line-height: 18px;
			font-weight: 300;
		}
		.frm_error_style, legend, .frm_none_container label, .frm_form_title {
			display: none !important;
		}
		.frm_none_container .frm_opt_container label{
			display: block !important;
		}

		.frm_description {
			@include ft($f_txt, 12px, 400, rgba($c_txt, 0.7), 19px);
			margin-top: 10px;
			font-style: italic;
			a {
				text-decoration: underline;
			}
		}
		.frm_hidden_container {
			> .frm_primary_label {
				display: none;
			}
		}

		.frm_required {
			color: $c_txt;
		}

		/* MISE EN PAGE */
		.form-field {
			position: relative;
			padding-bottom: 20px;
			&.frm_section_heading {
				margin-top: 30px;
				margin-bottom: 0;
				padding-bottom: 0;
				>h3:first-child{
					margin-top: 0;
				}
				&:after{
					content: '';
					display: block;
					clear: both;
				}
			}

			&.focused:not(.vertical_radio):not(.horizontal_radio):not(.classic-label){
				label{
					top: 7px;
					font-size: 12px;
					line-height: 16px;
					@include mobile {
						top: 8px;
						font-size: 11px;
						line-height: 14px;
					}
				}
			}
			//&.label-stay{
			//	.frm_primary_label{
			//		display: none;
			//	}
			//}
		}
	}

	// ERRORS
	.frm_message, .frm_error_style {
		text-align: center;
		background-color: $c_main;
		padding: 20px;
		p {
			font-size: 12px;
			line-height: 18px;
			font-weight: 300;
			color: white;
			margin-bottom: 0;
		}
	}
	.frm_error_style{
		background-color: $c_main;
	}
	.frm_required_field.frm_blank_field{
		textarea, input{
			border-color: rgba($c_error, .25);
			background-color: rgba($c_error, .05);
		}
	}
	.frm_required_field{
		.frm_opt_container{
			.frm_checkbox:last-child:first-child label{
				&:after{
					content: '*';
					position: relative;
					display: inline-block;
					margin-left: 3px;
				}
			}
		}
	}

	ul {
		li {
			&:before {
				content: none;
			}
		}
	}

	/* Gestion des labels */
	label:not(.title-facette) {
		position: absolute;
		top: 20px;
		left: 30px;
		@include ft($f_txt, 14px, 300, $c_txt, 20px);
		box-sizing: border-box;
		display: block;
		clear: both;
		text-indent: 0;
		padding: 0;
		user-select: none;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		margin-bottom: 6px;
		@include addTransition();
		@include mobile{
			font-size: 13px;
		}
		a {
			color: $c_main;
			text-decoration: underline;
			&:hover, &:focus {
				body:not(.no-hover) & {
					color: darken($c_main, 10%);
				}
			}
		}
	}
	.frm_primary_label{
		@include ft($f_txt, 14px, 300, $c_txt, 20px);
		box-sizing: border-box;
		display: block;
		clear: both;
		text-indent: 0;
		padding: 0;
		user-select: none;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		margin-bottom: 6px;
	}

	/* Input, Textarea, Select */
	input, select, textarea {
		@include ft($f_txt, 14px, 300, $c_txt, 20px);
		@extend %appearance;
		box-sizing: border-box;
		display: block;
		width: 100%;
		max-width: 100%;
		padding: 19px 30px;
		background: $c_white;
		border: 1px solid $c_border;
		text-overflow: ellipsis;
		border-radius: 5px;
		@include addTransition();
		&::placeholder {
			color: $c_txt;
		}
		&:hover, &:focus {
			body:not(.no-hover) & {
				border-color: $c_main;
			}
		}
	}

	input {
		&[type="date"]::-webkit-inner-spin-button {
			display: none;
		}
		&[type="date"]::-webkit-calendar-picker-indicator {
			display: none;
		}
	}

	input::-webkit-outer-spin-button,
	input::-webkit-inner-spin-button {
		/* display: none; <- Crashes Chrome on hover */
		-webkit-appearance: none;
		margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
	}

	input[type=number] {
		-moz-appearance: textfield; /* Firefox */
	}

	/*Change text in autofill textbox*/
	input:-webkit-autofill {
		-webkit-text-fill-color: $c_txt;
	}

	/* Change the white to any color ;) */
	input:-webkit-autofill,
	input:-webkit-autofill:hover,
	input:-webkit-autofill:focus,
	input:-webkit-autofill:active {
		-webkit-box-shadow: 0 0 0 60px $c_white inset !important;
	}

	textarea {
		height: 200px;
	}

	/* Style for Submit Button */
	.frm_submit {
		margin: 20px 0 10px;
		text-align: center;
		@include clearfix();
		@include mobile {
			margin: 15px 0 0;
		}
		button {
			display: inline-flex;
			align-items:center;
			width: auto;
			@include ft($f_txt, 14px, 500, $c_white, 26px);
			background-color: $c_main;
			padding: 6px 25px 8px;
			border: none;
			text-decoration: none;
			max-width: 100%;
			text-align: center;
			border-radius:50px;
			@include addTransition();
			@extend %bgLtR;

			&:after{
				content:'\e90b';
				font-family:'icomoon';
				position: relative;
				display: inline-block;
				font-size: 14px;
				line-height: 1;
				margin-left: 9px;
				transform:translate3d(0,0,0);
				@include addTransition();
			}

			@include mobile {
				padding: 6px 15px 8px;
				line-height: 22px;
			}

			&:hover, &:focus {
				body:not(.no-hover) & {
					cursor: pointer;
					color: white;

					&:after{
						transform:translate3d(5px,0,0);
					}
				}
			}

			&.frm_prev_page {
				float: left;
			}
			&.frm_final_submit {
				float: right;
			}
		}
	}

	/* Style for Radio & Checkbox */
	.frm_opt_container {
		font-size: 0;
		margin-bottom: 0;
		@include mobile {
			display: block;
		}

		& + .frm_error {
			margin-top: 15px;
		}

		input {
			display: none !important;
		}

		label:not(.star-rating):not(.frm_hidden):not(.star-rating):not(.frm_switch) {
			position: relative;
			display: block;
			left: 0;
			top: 0;
			padding-left: 32px;
			margin: 0;
			@include ft($f_txt, 14px, 300, $c_txt, 20px);
			white-space: normal;
			overflow: visible;
			text-indent: 0;
			@include addTransition();
			@include mobile{
				font-size: 13px;
			}
			&:hover {
				cursor: pointer;
				body:not(.no-hover) & {
					color: $c_main;
					span {
						&:before {
							border-color: $c_main;
						}
					}
				}
			}
		}

		.frm_checkbox, .frm_radio {
			margin-bottom: 10px;
			@include mobile {
				display: block;
			}
		}

		/* Style for Radio */
		.frm_radio {
			position: relative;
			input[type="radio"]:checked + span:after {
				opacity: 1;
				transform: scale(1);
			}

			span {
				&:before {
					content: '';
					position: absolute;
					top: 0;
					left: 0;
					width: 20px;
					height: 20px;
					border-radius: 50px;
					border: 1px solid $c_border;
					cursor: pointer;
					@include addTransition();
				}
				&:after {
					content: '';
					position: absolute;
					width: 8px;
					height: 8px;
					top: 6px;
					left: 6px;
					background: $c_main;
					border-radius: 50%;
					opacity: 0;
					transform: scale(0);
					@include addTransition();
				}
			}
		}

		/* Style for Checkbox */
		.frm_checkbox {
			position: relative;
			input {
				display: none;
				&:checked {
					~ span:after {
						opacity: 1;
						transform: scale(1);
					}
				}
			}
			label {
				> span {
					&:before {
						content: '';
						position: absolute;
						top: 0;
						left: 0;
						width: 20px;
						height: 20px;
						border: 1px solid $c_bg;
						border-radius: 2px;
						cursor: pointer;
						@include addTransition();
					}
					&:after {
						content: '\e911';
						font-family:'icomoon';
						position: absolute;
						display: flex;
						align-items:center;
						justify-content:center;
						top: 0;
						left: 0;
						width: 20px;
						height: 20px;
						background: $c_main;
						opacity: 0;
						color: white;
						font-size: 10px;
						transform: scale(0);
						@include addTransition();
					}
				}
			}
		}

		/* Style for Scale */
		.frm_scale {
			position: relative;
			display: inline-block;
			width: 40px;
			@include mobile {
				margin-bottom: 10px;
			}
			label {
				padding: 0;
				text-align: center;
				&:hover {
					body:not(.no-hover) & {
						cursor: pointer;
						input {
							border-color: $c_main;
						}
					}
				}
			}
			input {
				position: relative;
				display: block !important;
				@extend %appearance;
				width: 12px;
				height: 12px;
				border-radius: 50%;
				background: $c_white;
				border: 1px solid rgba($c_dark, 0.5);
				box-shadow: 0 0 15px rgba($c_dark, 0.2);
				padding: 0;
				margin: 0 auto 4px auto;
				box-sizing: content-box;
				cursor: pointer;
				@include addTransition();
				&:after {
					content: '';
					position: absolute;
					width: 6px;
					height: 6px;
					top: 3px;
					left: 3px;
					background: $c_main;
					border-radius: 50%;
					opacity: 0;
					transform: scale(0);
					@include addTransition();
				}
				&:checked {
					&:after {
						opacity: 1;
						transform: scale(1);
					}
				}
				&:hover, &:focus {
					body:not(.no-hover) & {
						border-color: $c_dark;
					}
				}
			}
		}
	}

	/* Style for Hours */
	.frm_time_wrap {
		position: relative;
		@include clearfix();
		> * {
			float: left;
			box-sizing: border-box;
		}
		> span {
			position: relative;
			width: 70% !important;
			@include clearfix();
			padding-right: 2.5%;
			> * {
				float: left;
				width: calc(50% - 16px) !important;
			}
			.frm_time_sep {
				line-height: 60px;
				padding: 0 2px;
				width: 26px !important;
				text-align: center;
			}
		}

		> * {
			width: 30% !important;
			box-sizing: border-box;
		}
	}

	/* Style for Selectric */
	.selectric-wrapper {
		.selectric {
			box-sizing: border-box;
			background: white;
			border:1px solid $c_border;
			border-radius: 5px;
			min-height: 60px;
			@include addTransition();
			&:hover {
				body:not(.no-hover) & {
					border-color: $c_main;
					.label:after{
						color: $c_main;
					}
				}
			}
			.label {
				@include ft($f_txt, 14px, 300, $c_txt, 20px);
				box-sizing: border-box;
				display: block;
				height: auto;
				margin: 0;
				padding: 19px 50px 19px 30px;
				@include mobile{
					font-size: 13px;
				}
				&:after {
					content: "\e92a";
					font-family:'icomoon';
					font-size: 14px;
					position: absolute;
					color: $c_txt;
					display: flex;
					justify-content:center;
					align-items: center;
					background-color: transparent;
					width: 14px;
					height: 14px;
					top: 50%;
					right: 28px;
					margin-top: -7px;
					transform:none;
					margin-left: 0;
					@include addTransition();
				}
			}
			.button {
				display: none;
			}
		}
		.selectric-items {
			border: none;
			background: $c_white;
			box-shadow: 0 5px 20px rgba($c_dark, 0.5);
			.selectric-scroll {
				ul {
					padding: 0;
					li {
						@include ft($f_txt, 14px, 300, $c_txt, 20px);
						display: block;
						padding: 10px 20px;
						margin-bottom: 0;
						@include addTransition();
						@include mobile{
							padding: 10px 20px;
							font-size: 13px;
						}
						&:hover {
							background: #F5F5F5;
						}
						&.selected {
							background: #EEE;
						}
					}
				}
			}
		}

		&.selectric-open{
			.selectric{
				border-color: $c_main;
				.label:after{
					color: $c_main;
				}
			}
		}
	}

	/* Style for Address */
	.frm_combo_inputs_container {
		.frm_form_field {
			.frm_hidden, .frm_screen_reader {
				//display: block !important;
				margin-bottom: 5px;
			}
			&:last-child {
				padding-bottom: 0;
			}
			&.form-field:not(.horizontal_radio):not(.vertical_radio):not(.classic-label) {
				label {
					padding: 0 !important;
					margin: 0 0 5px 0;
				}
			}
		}
	}

	/* Style for Upload file */
	.input-type-file {
		> label {
			display: none !important;
			width: calc(100% - 80px);
			padding-right: 130px;
			z-index: 1000;
			pointer-events: none;
		}

		.fallback {
			width: 100%;
			>input{
				display: none;
			}
		}
		.jfilestyle {
			display: block;
			border: 1px dashed $c_border;
			border-radius: 5px;
			@include addTransition();
			> input {
				box-sizing: border-box;
				z-index: -0;
				width: calc(100% - 2px) !important;
				bottom: 1px;
				left: 1px;
				right: 1px;
				top: auto;
				color: $c_txt;
				font-weight: 300;
				padding:15px 20px;
				pointer-events: none;
				text-align: center;
				position: absolute;
				border: 0;
				@include mobile{
					padding-left: 20px;
					padding-right: 20px;
				}
			}

			&:hover, &:focus {
				body:not(.no-hover) & {
					border-color: $c_main;
				}
			}
		}

		.focus-jfilestyle {
			position: relative;
			top: 0;
			bottom: 0;
			right:0;
			left: 0;
			z-index: 20;
			label {
				position: relative;
				top: 0;
				left: 0;
				box-sizing: border-box;
				padding:40px 20px;
				font-family: $f_txt;
				font-size: 14px;
				line-height: 20px;
				color: $c_txt;
				font-weight: 300;
				text-align: center;
				cursor: pointer;
				margin-bottom: 0;
				&:before{
					content: '\e940';
					display: block;
					font-family:'icomoon';
					text-align: center;
					color: $c_txt;
					font-size: 30px;
					line-height: 1;
					margin-bottom: 5px;
					font-weight: 400;
					@include addTransition();
				}

			}
		}
	}
	.frm_dropzone{
		.fallback{
			position: relative;
			z-index: 20;
		}
		.dz-message{
			display: none;
		}
	}

	/* Switch */
	.frm_switch_block{
		position: static;
		input{
			display: none;
			&:checked{
				+.frm_switch{
					.frm_slider{
						background-color: $c_main;
						&:before{
							left:35px;
						}
					}
				}
			}
		}
		.frm_switch{
			position: absolute;
			top: -1px;
			left: 0;
			width: 60px;
			height: 30px;
			.frm_slider{
				background-color: $c_bg;
				&:before{
					width: 20px;
					height: 20px;
					top: 5px;
					left: 5px;
				}
			}
		}
	}
	.classic-label{
		.frm_primary_label{
			cursor: pointer;
			display: block;
			width: 100%;
			box-sizing: border-box;
			padding-left: 60px;
			white-space: normal;
			a{
				color: $c_main;
			}
		}
	}
	.input-is-switch{
		label{
			position: static;
			top: 0;
			left: 0;
			padding-left: 80px;
			color: $c_txt;
			white-space:normal;
			cursor: pointer;
			a{
				color: $c_txt;
			}
		}
	}

	.frm_verify{
		display: none;
	}

	//Titre
	h3{
		position: relative;
		@include ft($f_hx, calculateRem($s_h3), 700, $c_main, calculateRem(30px));
		display: block;
		margin-top: 35px;
		margin-bottom: 16px;
		@include mobile {
			font-size: calculateRem(20px);
			line-height: calculateRem(20px);
		}
	}
	.surtitre{
		margin-bottom: -10px;
	}

	//Time
	.input-is-time{
		.frm_time_wrap{
			.focused{
				display: flex;
				align-items: center;
				width: 100% !important;
				padding-right: 0;

				.selectric-wrapper{
					width: 50% !important;
				}
				.select-custom{
					position: absolute;
				}
				.frm_time_sep{
					flex-shrink: 0;
				}
			}
		}
	}
}

.frm-g-recaptcha{
	visibility: hidden !important;
	opacity: 0 !important;
}
