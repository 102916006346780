.th-slider-wrapper {
	overflow: hidden;
}

.th-slider {
	position: relative;
	width: 100%;
	display: flex;
	user-select: none;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;

	> * {
		user-select: none;
		-webkit-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		display: block;
		flex-shrink: 0;

		&:first-child {
			margin-left: 0 !important;
		}

		&:last-child {
			margin-right: 0 !important;
		}
	}
}

.wrapper:not(.wrapper-small) {
	.scroll-mode {
		@include desktop {
			padding-left: 80px;
			padding-right: 80px;
			scroll-padding: 80px;
		}
		@include tablet-landscape {
			padding-left: 40px;
			padding-right: 40px;
			scroll-padding: 40px;
		}
		@include mobile {
			padding-left: 20px;
			padding-right: 20px;
			scroll-padding: 20px;
		}

		& > *:last-child {
			padding-right: 0;
		}
	}
}

.scroll-mode-parent {
	@include tablet-landscape {
		width: 100%;
		margin: 0;
	}
	.scroll-mode {
		overflow: scroll;
		overflow-y: hidden;
		scroll-snap-type: x mandatory;
		-webkit-overflow-scrolling: touch;

		@include tablet-landscape {
			padding-left: 40px;
			padding-right: 0px;
			scroll-padding: 40px;
		}
		@include mobile {
			padding-left: 20px;
			padding-right: 0px;
			scroll-padding: 20px;
		}


		&:after {
			@include tablet-landscape {
				content: '';
				min-width: 40px;
			}
			@include mobile {
				content: '';
				min-width: 20px;
			}
		}


		> * {
			scroll-snap-align: start;

			&:last-child {
				margin-right: 0;
			}
		}

		> *:last-child {
			scroll-snap-align: end;
		}
	}
}

