
.overlay-menu {
    z-index: 10001;
    max-width: 100%;
    opacity: 1;
    visibility: visible;
    pointer-events: none;
    background-color: transparent;
    @include addTransition();

    >*{
        pointer-events: auto;
    }

    //Close
    .btn-close{
        position: absolute;
        top: 50px;
        right: 30px;
        z-index: 30;
        background-color: transparent;
        border: none;
        font-size: 14px;
        @include tablet-portrait{
            top: 20px;
            right: 20px;
        }

        @include can-hover{
            color: $c_sub;
            background-color: transparent;
            border: none;
        }
    }

    //Partie main menu
    &__right {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        width: 440px;
        padding: 140px 80px 60px;
        overflow: hidden;
        display: flex;
        @extend %bgTtB;
        z-index: 10;
        @media screen and (max-height: 680px) and (min-width: 981px){
            padding: 80px 80px 40px;
        }
        @include tablet-portrait{
            width: 100%;
            padding: 0 40px;
            overflow: auto;
        }

        .container{
            margin: 0;
            width: 100%;
            display: block;
            @include tablet-portrait{
                padding: 60px 0;
            }
        }
    }
    //Partie sub menu
    &__left {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        width: calc(100% - 440px);
        pointer-events: none;
        z-index: 20;
        @include tablet-portrait{
            width: 100%;
        }
    }


    // Nav primary
    // ==========================================================================
    .nav-primary{
        display: block;
        //a et label
        &__link{
            @include ft($f_hx, calculateRem(50px), 300, $c_white, 45px);
            @include addTransition();
            position: relative;
            display: block;
            padding: 12px 0;
            font-style: italic;
            cursor: pointer;
            transform:translate3d(0,0,0);
            @media screen and (max-height: 650px) and (min-width: 981px){
                font-size: calculateRem(35px);
                line-height: calculateRem(30px);
                padding: 10px 0;
            }
            @include tablet-portrait{
                text-align: center;
            }
            @include mobile{
                font-size: calculateRem(40px);
                line-height:calculateRem(35px);
                padding: 15px 0;
            }

            @include can-hover{
                color: $c_txt;
                .subtitle-menu{
                    color: $c_txt;
                }
            }

            &:before{
                content: '\e945';
                font-family:'icomoon';
                font-size: 39px;
                color: white;
                position: absolute;
                top: 14px;
                left: -75px;
                opacity: 0;
                @include addTransition();
            }

            &.actif{
                transform:translate3d(-20px,0,0);
                color: $c_txt;
                &:before{
                    color: $c_txt;
                    opacity: 1;
                }
                .subtitle-menu{
                    color: $c_txt;
                }
            }


            // sous-titre
            .subtitle-menu{
                @include ft($f_txt, calculateRem(16px), 400, $c_white, 20px);
                display: block;
                font-style: normal;
                margin-top: 5px;
                @include addTransition();
            }


        }


        &__input{
            display: none;
            &:checked{
                +.nav-primary__submenu-wrapper{
                    opacity: 1;
                    pointer-events: auto;
                }
            }
        }
        //Sous-menus
        &__submenu-wrapper{
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            background-color: $c_txt;
            opacity: 0;
            pointer-events: none;
            padding: 140px 80px 60px 190px;
            overflow: auto;
            @include addTransition();
            @media screen and (max-height: 680px) and (min-width: 981px){
                padding: 80px 80px 60px 190px;
            }
			@media screen and (max-width: 1150px) {
				padding-left: 80px;
			}
			@include tablet-portrait {
				text-align: center;
				padding: 60px 40px;
			}
			@include mobile {
				padding: 60px 20px;
			}

			@extend %feuille-blanche-full;

			&:before {
				position: fixed;
				top: auto;
				width: 388px;
				height: 271px;
				transform: rotate(-25deg);
				bottom: -90px;
				left: -80px;
				background-size: contain;
				z-index: 0;
				@include mobile {
					display: none;
				}
			}

			&__submenu-back {
				position: relative;
				display: none;
				color: white;
				text-transform: uppercase;
				font-weight: 700;
				letter-spacing: 1px;
				font-size: 22px;
				line-height: 1;
                margin-bottom: 20px;
                text-align: center;
                padding-left: 35px;
                span{
                    position: absolute;
                    bottom: -1px;
                    left: 0;
                }
                @include tablet-portrait{
                    display: inline-block;
                }
                @include mobile{
                    font-size: 18px;
                    span{
                        bottom: 1px;
                    }
                }
            }
            &__link{
                position: relative;
                display: block;
                @include ft("", calculateRem(22px), 400, $c_white, 28px);
                padding: 9px 0;
                @include addTransition();
                @media screen and (max-height: 680px) and (min-width: 981px){
                    font-size: 18px;
                    line-height: 24px;
                    padding: 7px 0;
                }
                @include tablet-portrait{
                    text-align: center;
                }
                @include mobile{
                    font-size: 18px;
                    line-height: 24px;
                    padding: 7px 0;
                }

                @include can-hover{
                    font-weight: 600;
                    color: white;
                }
            }
        }
    }


    // Nav secondary
    // ==========================================================================
    .nav-secondary{
        display: block;
        //ul
        &__menu{
            padding: 0;
            margin-top: 35px;
            @media screen and (max-height: 650px) and (min-width: 981px){
                margin-top: 15px;
            }
        }
        //a et label
        &__link{
            @include ft("", calculateRem(18px), 600, $c_white, 26px);
            @include addTransition();
            position: relative;
            display: block;
            padding: 8px 0;
            @media screen and (max-height: 650px) and (min-width: 981px){
                font-size: calculateRem(16px);
                line-height: 22px;
                padding: 5px 0;
            }
            @include tablet-portrait{
                text-align: center;
            }
            @include mobile{
                font-size: calculateRem(16px);
                line-height: 22px;
                padding: 5px 0;
            }

            @include can-hover {
                color: $c_txt;
            }
        }
    }


    // Langues
    // ==========================================================================
    .lang{
        margin-top: 35px;
        @media screen and (max-height: 650px) and (min-width: 981px){
            margin-top: 25px;
        }
        @include tablet-portrait{
            text-align: center;
        }
        &__item{
            display: inline-block;
            margin-right: 15px;
            &:last-child{
                margin-right: 0;
            }
        }
        &__link{
            @include ft("", calculateRem(14px), 400, $c_white, 24px);
            text-transform: uppercase;

            &.active{
                font-weight: 700;
            }

            @include can-hover {
                color: $c_txt;
            }
        }
    }
}
