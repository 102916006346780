.bloc-relation {
	padding-bottom: 60px !important;
	@include mobile{
		padding-bottom: 40px !important;
	}

	.wrapper:last-of-type {
		&.scroll-mode-parent {
			margin: 0;
			width: 100%;
			max-width: none;
		}
	}

	//Bloc texte accroché au bloc relation
	.text-styles{
		margin-bottom: 30px;
		>div{
			max-width: calc(100% - 510px);
			padding-right: 20px;
			>*:first-child{
				margin-top: 0;
			}
			@include tablet_landscape{
				max-width: calc(100% - 300px);
			}
			@include tablet_portrait{
				max-width: 100%;
				padding-right: 0;
			}
		}
	}
	> .container-nav {
		display: flex;
		justify-content: flex-end;
		margin-bottom: 30px;
		@include mobile {
			flex-direction: column;
			justify-content: flex-start;
			margin-bottom: 20px;
		}

		.btn, .btn-primary, .btn {
			margin-left: auto;
			@include mobile {
				margin-left: 0;
				margin-right: auto;
			}
		}

		.nav-buttons{
			margin-right: auto;
		}
	}
	.container-slider-relation {
		&.scroll-mode-parent {
			margin: 0;
			max-width: none;
		}

		.image {
			@include mobile {
				margin-right: 0;
			}
		}
	}

	.nav-buttons {
		@include mobile {
			display: none;
		}
	}
	.nav-dots {
		@include min-mobile {
			display: none;
		}
	}

	.card {
		width: 360px;
		height: 100%;
		margin-right: 30px;
		margin-bottom: 0;
		@include mobile {
			width: calc(100% - 20px);
			flex-direction: column;
			margin-right: 10px;
		}

		.image {
			width: 100%;
			height: auto;
			@include mobile{
				&:before{
					content: '' !important;
				}
			}

		}
	}


	&.bloc-relation-reversed {
		.th-slider {
			@include min-tablet_portrait {
				direction: rtl;
				& > *:first-child {
					margin-left: 20px !important;
				}
				.card {
					margin-right: 0;
					margin-left: 20px;
					direction: ltr;
				}
			}
		}

	}
}