#pagination {
	display: flex;
	align-items: center;
	margin-top: 80px;
	text-align: center;
	clear: both;
	background: $c_white;
	z-index: z-index('pagination');
	flex-wrap: wrap;
	justify-content: space-between;

	@include mobile {
		padding-top: 40px;
		justify-content: center;
		text-align: center;
		margin-top: 40px;
	}

	.pagination-right {
		display: flex;
		align-items: center;
		@include min-mobile {
			margin-left:auto;
		}
	}

	&:empty {
		display: none;
	}

	> a, strong, span {
		@include ft($f_spe, 10px, 400, $c_txt, 1.5);
		position: relative;
	}

	.btn-square {
		font-size: 0;
		z-index: 4;
		border:none;

		&.prev {
			&:before {
				content: "\e909";
			}
		}

		&.next {
			&:before {
				content: "\e90b";
			}
		}

		&:hover, &:focus {
			body:not(.no-hover) & {
				cursor: pointer;
				background:none;
				border:none;
				opacity:0.8;
			}
		}
	}

	.pagination-number {
		position: relative;
		z-index: 10;
		display: flex;
		align-items: center;
		margin-left: auto;
	//	margin-right: 25px;

		@include mobile {
			margin-right: auto;
		}

		> * {
			padding: 10px 14px;
			font-size: 16px;
			font-weight: 400;
			color: $c_txt;
			text-align: center;
			display: inline-block;
			vertical-align: middle;

			@include mobile {
				padding:10px 7px;
			}
			@include mobile-small {
				padding: 10px 2px;
			}

			&.active {
				color: $c_main;
				font-weight: 700;
				position:relative;

				&:after {
					content:"\e945";
					position:absolute;
					@include icomoon();
					bottom:0;
					font-size:10px;
					left:0;
					right:0;
				}
			}
		}

		a {
			margin: 0;
			@include addTransition();

			&:hover, &:focus {
				body:not(.no-hover) & {
					color: $c_main;
				}
			}
		}

		> span {
			vertical-align: bottom;
		}
	}

	.resultats {
		font-size: 16px;
		line-height: 1.5;
		font-weight: 400;

		@include mobile {
			width: 100%;
			flex-shrink: 0;
			margin-bottom: $small-margin;
		}

		span {
			font-size: 16px;
			line-height: 1.5;
			font-weight: 400;
		}
	}
}