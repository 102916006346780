.strate-footer-newsletter {
	position: relative;

	.texte{
		position: relative;
		padding-bottom: 20px;
		& p:last-child {
			margin-bottom: 0;
		}

		&:before{
			content: '';
			position: absolute;
			top: -40px;
			left: 0;
			bottom: -62px;
			right: 0;
			background-color: $c_bg-light;
			z-index: -1;
			@include tablet-portrait{
				bottom: -31px;
			}
		}

		.surtitre + .h3, h3 {
			margin-top:0;
		}

		.h3, h3 {
			font-size:40px;
			line-height:45px;
			margin-bottom:9px;
		}
	}

	.news-wrapper{
		position: relative;
		@extend %patternBG-top;
		&:before{
			background: $c_bg;
			top: 0;
			z-index: -1;
		}
		&:after{
			content: '';
			position: absolute;
			top: 62px;
			left: 0;
			bottom: -40px;
			right: 0;
			background-color: $c_bg;
			z-index: -1;
			@include tablet-portrait{
				top: 31px;
			}
		}
		form {
			position: relative;
			width: 100%;

			.frm_error {
				@include ft($f_txt, 12px, 400, $c_white, 18px);
				background: $c_error;
				position: relative;
				display: inline-block;
				margin: 0 55px;
				padding: 4px 10px;
				border-radius: 4px;
			}

			.frm_error_style {
				display: none;
			}

			.frm_hidden, label {
				display: none;
			}

			input {
				@include ft($f_txt, 20px, 400, $c_txt, 26px);
				@extend %appearance;
				width: 100%;
				padding: 35px 220px 35px 40px;
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
				display: inline-block;
				vertical-align: top;
				background: $c_white;
				box-shadow: 0px 30px 60px rgba($c_dark, 0.10);
				border-radius: 50px 50px 5px 50px;
				@include addTransition();
				@include mobile {
					font-size: 20px;
					line-height: 1.15;
					padding: 20px 30px;
				}

				&::placeholder {
					color: $c_txt;
				}

				&:hover, &:focus {
					body:not(.no-hover) & {
						outline: none;
						box-shadow: 0px 30px 60px rgba($c_dark, 0.05);
					}
				}

				& + .frm_error {
					margin-top: 10px;
				}
			}

			/*Change text in autofill textbox*/
			input:-webkit-autofill {
				-webkit-text-fill-color: $c_txt;
			}

			/* Change the white to any color ;) */
			input:-webkit-autofill,
			input:-webkit-autofill:hover,
			input:-webkit-autofill:focus,
			input:-webkit-autofill:active {
				-webkit-box-shadow: 0 0 0 100px $c_white inset !important;
			}

			.frm_top_container {
				position: relative;
			}

			.frm_submit {
				position: absolute;
				top: 28px;
				right: 40px;
				@include mobile {
					position: relative;
					margin-top: 20px;
					right: 0;
					top: 0;
				}

				.frm_button_submit {
					@include ft($f_txt, 14px, 400, $c_white, 16px);
					@extend %appearance;
					width: auto;
					min-width: 0;
					min-height: 0;
					margin: 0;
					padding: 10px 25px 14px;
					cursor: pointer;
					border-radius: 50px;
					@include addTransition();

					@include mobile {
                        display: block;
						width: 100%;
						height: auto;
						text-align: center;
					}
                    &:after{
                        vertical-align: middle;
                        margin-top: -2px;
                    }
				}
			}

			/* Checkbox */
			.frm_opt_container {
				position: relative;
				margin-top: 20px;

				.frm_primary_label {
					display: none;
				}

				input {
					display: none;

					&:checked {
						~ span:before {
							background: $c_main;
						}

						~ span:after {
							left: 24px;
							background: $c_white;
						}
					}
				}

				label {
					@include ft($f_txt, 12px, 400, white, 18px);
					position: relative;
					display: block;
					padding: 0px 0 10px 60px;
					text-align: left;
					user-select: none;
					@include addTransition();

					&:hover {
						body:not(.no-hover) & {
							cursor: pointer;
							color: $c_main;
						}
					}

					a {
						position: relative;
						display: inline-block;
						text-decoration: underline;
						color: $c_main;
					}
				}

				span {
					&:before {
						content: '';
						position: absolute;
						top: 4px;
						left: 0;
						width: 40px;
						height: 20px;
						border-radius: 50px;
						cursor: pointer;
						background: rgba(white, .3);
						@include addTransition();
					}

					&:after {
						content: '';
						width: 12px;
						height: 12px;
						border-radius: 50%;
						position: absolute;
						top: 8px;
						left: 4px;
						background: $c_white;
						@include addTransition();
					}
				}
			}

		}
	}

}