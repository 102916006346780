.article{
	.bloc-relation{
		.text-styles{
			h2{
				text-align: center;
				&:after{
					left: 50%;
					margin-left: -31px;
				}
			}
		}

		.bouton{
			margin-top: 35px;
			display: block;
			text-align: right;
		}
	}

	.article-content{
		figure{
			background-color: transparent;
		}
		img{
			max-width: 100%;
			height: auto;
			overflow: hidden;
			border-radius: 50px 50px 5px 50px;
			@include mobile{
				border-radius: 30px 30px 5px 30px;
			}
		}
	}


	+.barre-single-sit >.wrapper:first-of-type{
		.container{
			margin-right: auto;
		}
	}
}