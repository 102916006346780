.accordeon {
	position: relative;
	text-align: left;
	margin: auto;
	border: 1px solid $c_sub;
	@include addTransition();

	@include tablet-portrait {
		margin: 0;
	}

	&:nth-of-type(even) {
		border-top: none;
		border-bottom: none;
	}

	&:last-of-type {
		border-bottom: 1px solid $c_sub;

		&.open {
			border-bottom: none;
		}
	}

	.title-accordeon {
		@include ft($f_txt, $s_small_title, 700, $c_txt, 1.2);
		@extend %appearance;
		position: relative;
		background: none;
		display: flex;
		align-items: center;
		width: 100%;
		text-align: left;
		padding: 35px 0;
		z-index: 1;
		cursor: pointer;
		min-height: 95px;
		@include addTransition();

		@include mobile {
			font-size: 14px;
			line-height: 1.14;
		}

		[class^="icon-"], [class*=" icon-"] {
			color: $c_sub;
			font-size: 40px;
			position: absolute;
			left: 30px;

			@include mobile {
				font-size: 20px;
				left: 20px;
			}
		}

		.container-custom {
			max-width: none;
			padding-left: 95px;
			padding-right: 95px;
			margin: 0 auto;

			@include mobile {
				padding-left: 55px;
				padding-right: 55px;
			}
		}

		&:before {
			content: "\e936";
			@include icomoon();
			font-size: 20px;
			color: $c_dark;
			position: absolute;
			right: 40px;

			@include mobile {
				right: 20px;
			}
		}

		span {
			flex-grow: 1;
			max-width: 70%;
		}
	}


	.item {
		position: relative;
		max-height: 0;
		overflow: hidden;
		font-size: 0;

		&.container-custom {
			max-width: none;
			padding-left: 95px;
			margin: 0 auto;

			@include mobile {
				padding-left: 20px;
				padding-right: 20px;
			}
		}

		&.anim {
			@include addTransition();
		}

		> * {
			position: relative;
			margin-top: 40px !important;
			margin-bottom: 40px !important;

			@include mobile {
				margin-bottom: 40px !important;
			}

			.title {
				@include mobile {
					font-size: 25px;
					line-height: 0.8;
				}
			}
		}
	}

	.strate {
		padding: 0;
	}

	&.open {
		border: none;
		border-bottom: 1px solid $c_sub;

		.title-accordeon {
			color: $c_white;
			background-color: $c_sub;

			[class^="icon-"], [class*=" icon-"] {
				color: $c_white;
			}

			&:before {
				content: "\e935";
				color: $c_white;
			}
		}

	}

	&:hover, &:focus {
		body:not(.no-hover) & {
			cursor: pointer;

			.title-accordeon {
				&:before {
					box-shadow: 0 0 0 0px rgba(227, 5, 20, 0.35);
				}
			}
		}
	}
}