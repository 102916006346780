#barre-navigation {
	background: transparent;
	position: absolute;
	top: 70px;
	left: 0;
	right: 0;
	z-index: z-index('barre-navigation');
	height: var(--header-height);
	display: flex;
	align-items:center;
	justify-content:space-between;
	padding-right:300px;
	padding-left: 80px;
	@include addTransition();

	@include tablet-portrait{
		justify-content: flex-end;
		padding-right: 80px;
		padding-left: 80px;
	}

	@include mobile{
		top: 50px;
		padding-right: 40px;
		padding-left: 40px;
	}

	& ~ main {
		margin-top: 40px;
		@include mobile{
			margin-top: 20px;
		}
	}

	.barre-navigation__menu{
		display: flex;
		align-items:center;
		max-width:50%;
		@include tablet-portrait{
			display: none;
		}
		&__item{
			display: inline-block;
			&:last-child{
				a{
					padding-right: 0;
				}
			}
		}
		&__link{
			color: white;
			font-size: 16px;
			line-height: 26px;
			padding-right: 35px;
			cursor: pointer;
			&:hover{
				body:not(.no-hover) & {
					color: $c_main;
				}
			}
		}

	}
	// Logo du site
	.logo {
		position: absolute;
		top: -10px;
		left: 50%;
		transform:translate3d(-50%,0,0);

		figure {
			background: none;
			position: relative;

			img {
				height: 100%;
			}
		}
	}

	// Dropdown en css (donc pas un select field) des langues du site
	.lang-dropdown {
		position: relative;
		display: flex;
		align-items: center;
		margin-left: 20px;

		&:hover {
			cursor: pointer;
		}

		a {
			@include ft($f_txt, 14px, 400, white, 26px);
			text-align: center;
			text-transform: uppercase;
			padding: 0 10px;
			display: block;

			&:hover, &:focus {
				body:not(.no-hover) & {
					color: $c_sub;
				}
			}
		}

		> a {
			font-weight: 600;
			&:after {
				content: "\e92a";
				@include icomoon();
				display: inline-block;
				margin-left: 6px;
				margin-top: -3px;
				font-size: calculateRem(10px);
			}
		}

		&.one-language {
			> a {
				&:after {
					content:"";
				}
			}
		}


		ul {
			display: flex;
			flex-direction: column;
			align-items: center;
			@extend %bgTtB;
			border-radius:5px;
			width: auto;
			padding: 0 0;
			overflow: hidden;
			transform-origin: top;
			height: auto;
			opacity: 0;
			position: absolute;
			left: 0;
			top: calc(100% + 3px);
			@include addTransition();

			@include mobile {
				flex-direction: column;
				transform: scaleY(0);
				transform-origin: top;
				opacity: 0;
				position: absolute;
				left: 0;
				top: 90%;
			}

			li {
				display: flex;
				align-items: center;
				width: 100%;
				height: 100%;

				@include mobile {
					height: 40px;
				}

				a {
					color:white;
					&:hover {
						color: $c_bg !important;
					}
				}

			}

			&.open {
				opacity: 1;
				transform: scaleY(1);
			}
		}


	}

	.lang-select {
		position: relative;
		display: none;

		&:after {
			content: "\e92a";
			@include icomoon();
			font-size: 13px;
			font-weight: 700;
			color: white;
			display: block;
			position: absolute;
			right: 0;
			top: 11px;
		}

		@include tablet-portrait {
			display: flex;
			justify-content: center;
			align-items: center;
			margin-left: auto;
		}

		select{
			@include ft($f_txt, 14px, 700, white, 1);
			text-transform: uppercase;
		}

		.selectric-wrapper {
			position: relative;
			@include ft($f_txt, 14px, 700, white, 1);
			text-transform: uppercase;
			height: 100%;
			text-align: center;
			padding: 0 10px 0 0;
		}

	}


	.fixed-part{
		position: fixed;
		top: 70px;
		right: 0;
		left: auto;
		background-color: white;
		border-radius:30px 0 0 30px;
		display: block;
		height: auto;
		box-shadow: 0 10px 20px rgba(0,0,0,.08);
		padding-right: 20px;
		padding-left: 20px;

		@include tablet-portrait{
			top: auto;
			bottom: 20px;
		}


		// Toolbar avec la météo, la webcam, le lien vers la billetterie, le panier, les favoris
		.toolbar {
			display: flex;
			align-items: center;
			margin-left: auto;
			height:auto;

			li {
				position: relative;
				display: flex;
				align-items: center;
				height: auto;
				cursor: pointer;

					&:before {
						content: "";
						display: block;
						border: solid;
						border-color: $c_main transparent;
						border-width: .4em .4em 0 .4em;
						position: absolute;
						top: 10px;
						left: 50%;
						transform: translateX(-50%);
						z-index: z-index('barre-navigation');
						opacity: 0;
						@include addTransition();
					}

					&:after {
						content: attr(data-hover);
						display: block;
						@include ft($f_txt, 9px, bold, $c_white, 1.11);
						background-color: $c_main;
						border-radius: 5px;
						padding: 5px 10px;
						position: absolute;
						top: -10px;
						left: 50%;
						transform: translateX(-50%);
						opacity: 0;
						@include addTransition();
					}

				&:hover, &:focus {
					body:not(.no-hover) & {
						&:before, &:after {
							opacity: 1;
						}

						[class^="icon-"], [class*=" icon-"] {
							cursor: pointer;
							color: $c_sub;

							&[data-counter] {
								&:after {
									transform: scale(1.1);
								}
							}
						}
					}
				}

				a, button {
					display: flex;
					align-items: center;
					height: 100%;
					@include addTransition();
				}

				.btn-coeur {
					position: relative;

					.favoris-counter:not(:empty) {
						@include ft($f_txt, 8px, bold, $c_white, 1.37);
						position: absolute;
						right: 15px;
						top: auto;
						bottom: 12px;
						min-width: 15px;
						height: 15px;
						padding:0 4px;
						border-radius: 50px;
						background-color: $c_main;
						display: flex;
						justify-content: center;
						align-items: center;
						@include addTransition();
					}

					&:hover, &:focus {
						body:not(.no-hover) & {
							.favoris-counter:not(:empty) {
								cursor: pointer;
								transform: scale(1.1);
							}
						}
					}
				}

				[class^="icon-"], [class*=" icon-"] {
					position: relative;
					font-size: 20px;
					color: $c_txt;
					padding: 20px;

					&[data-counter] {
						&:after {
							content: attr(data-counter);
							@include ft($f_txt, 8px, bold, $c_white, 1.37);
							position: absolute;
							right: 15px;
							top: auto;
							bottom: 12px;
							min-width: 15px;
							height: 15px;
							padding:0 4px;
							border-radius: 50px;
							background-color: $c_main;
							display: flex;
							justify-content: center;
							align-items: center;
							@include addTransition();
						}
					}
				}

			}

		}

		// Bouton pour ouvrir l'overlay recherche
		.btn-search {
			@include tablet-portrait {
				margin-left: auto;
				display: none;
			}

			[class^="icon-"], [class*=" icon-"] {
				font-size: 20px;
				color: $c_main;
				padding: 0 40px 0 15px;
			}

			&:hover, &:focus {
				body:not(.no-hover) & {
					[class^="icon-"], [class*=" icon-"] {
						cursor: pointer;
						color: $c_sub;
					}
				}
			}
		}

		// Bouton pour ouvrir le menu de navigation fullscreen
		.btn-menu {
			position: relative;
		}
	}
}


