// Fil d'ariane
// ==========================================================================
#breadcrumb {
	text-overflow: ellipsis;
	display: flex;
	align-items: center;
	flex-wrap: wrap;
	margin: 37px 0 30px 0;

	@include tablet-portrait {
		margin-top: 20px;
	}
	@include mobile {
		margin: 15px 0;
	}

	li {
		position: relative;
		padding: 0;
		display: inline-flex;
		align-items:center;

		&:not(:last-of-type) {
			&:after {
				content: '\e90b';
				font-family:'icomoon';
				position: relative;
				display: inline-block;
				color: $c_txt;
				margin-right: 9px;
				font-size: 9px;
				line-height: 1;
				background-color: transparent;
			}
		}

		&:first-of-type {
			a {
				padding-left: 0;
			}
		}

		&:last-of-type {
			a {
				padding-right: 0;
			}
		}

		a {
			position: relative;
			@include ft($f_txt, 14px, 300, $c_txt, 26px);
			display: inline-block;
			padding: 0 9px 0 0;
			white-space: nowrap;
			max-width: 100%;
			text-decoration: none;
			@include addTransition();

			&:hover, &:focus {
				body:not(.no-hover) & {
					text-decoration: underline;
				}
			}
		}
		span{
			@include ft($f_txt, 14px, 300, $c_txt, 26px);
			opacity: .5;
		}

		&:before {
			display: none;
		}
	}
}