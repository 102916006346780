.bloc-guides {
	&.bloc{
		margin-top: 120px;
		@include tablet-portrait{
			margin-top: 95px;
		}
		@include mobile{
			margin-top: 60px;
		}
		+*.bloc{
			margin-top: 120px;
			@include tablet-portrait{
				margin-top: 95px;
			}
			@include mobile{
				margin-top: 60px;
			}
		}
	}

	&.guides-grille {
		background-color: $c_bg;
		padding: 40px 0 70px;
		@extend %patternBG-bottom;
		@extend %patternBG-top;
		&:after,&:before{
			background-color: $c_bg;
		}

		.text-styles{
			margin-bottom: 35px;
			h2{
				color: white;
			}
		}

		.container-elements {
			// On force le container à être plus large que son parent de 20px (parce que la gouttière entre les éléments est de 20px)
			// On met un margin left négatif pour aligner le container avec son parent
			width: calc(100% + 20px);
			margin-left: -10px;
			display: flex;
			align-items: center;
			flex-wrap: wrap;
			justify-content: flex-start;

			> * {
				position: relative;
				display: inline-block;
				margin-right: 10px;
				margin-left: 10px;
				background: transparent;
				margin-top: 20px;
				width: calc(25% - 20px);
				@include tablet-portrait {
					width: calc(50% - 20px);
				}

				img {
					width: 100%;
					height: auto;
				}

				// Le after suivant est juste un "trick" en css pour farcer le format carré responsive et éviter de devoir donner une chiffre précise
				&:after {
					content: "";
					display: block;
					padding-top: 100%;
				}

				.container {
					position: absolute;
					width: 100%;
					height: 100%;
					padding: 10px;
				}
			}
		}

	}

	.slide {
		.container {
			position: relative;
			width: 208px;
			height: 208px;
			background-color: white;
			padding: 40px;
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			border-radius:5px;
		}

		[class^="icon-"], [class*=" icon-"] {
			display: block;
			font-size: 60px;
			color: $c_bg;

			@media (max-width: 1100px) {
				font-size: 40px;
			}

            @include tablet-portrait{
                font-size: 60px;
            }

			@include mobile {
				font-size: 35px;
			}

			& + .title {
				margin-top: $small-margin;
				@include mobile{
					margin-top: 10px;
				}
			}
		}

		.title {
			font-family: $f_hx;
			font-size: 30px;
			line-height: 1;
			text-align: center;
			color: $c_bg;
			@include addTransition();

			@include mobile {
				font-size: 18px;
				line-height: 1;
			}
		}

		&:hover, &:focus {
			body:not(.no-hover) & {
				.container{
					@extend %bgTtB;
				}

				[class^="icon-"], [class*=" icon-"], .title {
					color: $c_white;
				}
			}
		}
	}
}