// Style Slick Slider
// ==========================================================================
.slick-carousel {
	height: 100%;

	&:not(.slick-initialized) {
		.item, .card {
			display: none;

			&:first-child {
				display: block;
			}
		}
	}

	.slick-list {
		height: 100%;

		.slick-track {
			height: 100%;
		}
	}

	.item {
		position: relative;
	}
}

// Style Dots
// ==========================================================================
.slick-dots {
	max-width: 1280px;
	margin: auto;
	position: absolute;
	left: 0;
	right: 0;
	width: 100%;
	bottom: 20px;
	@media screen and (min-width: 1600px) {
		max-width: 1520px;
	}
	@media screen and (max-width: 1360px) {
		margin: 0 40px;
		width: calc(100% - 80px);
	}
	@include mobile {
		margin: 0 20px;
		width: calc(100% - 40px);
	}

	li {
		display: inline-block;
		vertical-align: middle;
		margin: 0 3px;
		padding: 0;

		&:before {
			content: none;
		}

		&.slick-active {
			button {
				background: $c_white;
			}
		}

		button {
			width: 8px;
			height: 8px;
			background: rgba($c_white, 0.5);
			border-radius: 50%;
			cursor: pointer;
			display: block;
			border: none;
			padding: 0;
			font-size: 0;
			line-height: 0;
			outline: none;
			@include addTransition();

			&:hover, &:focus, &:active {
				background: $c_white;
			}
		}
	}
}

// Style Arrows
// ==========================================================================
.slick-prev, .slick-next {
	position: absolute;
	top: 50%;
	z-index: 100;
	margin-top: -25px;
	padding: 0;
	outline: none;
	overflow: visible;
	border: 1px solid rgba($c_dark, 0.5);
	border-radius: 50%;
	width: 48px;
	height: 48px;
	line-height: 48px;
	font-size: 0;
	background: none;
	display: block;
	text-align: center;
	float: left;
	@include addTransition();
	@include mobile {
		width: 34px;
		height: 34px;
		line-height: 34px;
	}

	&:not(.slick-disabled) {
		&:hover, &:focus, &:active {
			cursor: pointer;
			border-color: $c_main;
		}

	}

	&:before {
		@include icomoon();
		position: relative;
		font-size: 12px;
		@include mobile {
			font-size: 9px;
		}
	}

	&.slick-disabled {
		opacity: 0.3;
	}
}

.slick-prev {
	left: 40px;
	@include mobile {
		left: 20px;
	}

	&:before {
		content: "\e901";
	}
}

.slick-next {
	right: 40px;
	@include mobile {
		right: 20px;
	}

	&:before {
		content: "\e902";
	}
}