.bloc-brochures {
	.nav-wrapper {
		display: flex;
		justify-content: space-between;

		& + .container-slider {
			margin-top: 20px !important;
		}
	}

	.brochure {
		position: relative;
		width: 208px;
		margin-right: 20px;

		&:last-of-type {
			margin-right: 0;
		}

		.image-liens-container {
			position: relative;

			.image {
				position: relative;
				@include aspect-ratio(208, 249);

				figure, .no-photo {
					width: 100%;
					position: absolute;
					top: 0;
					left: 0;
					bottom: 0;
					border-radius: 5px;
					overflow: hidden;
				}
				figure{
					background-color: transparent;
				}
			}

			.liens {
				display: flex;
				align-items: center;
				position: absolute;
				right: 5px;
				bottom: -20px;

				a {
					box-sizing: border-box;
					display: flex;
					justify-content: center;
					align-items: center;
					width: 50px;
					height: 50px;
					margin-right: 5px;
					flex-shrink: 0;
					border-radius:50px;
					@include addTransition();

					&:last-of-type {
						margin-right: 0;
					}

					[class^="icon-"], [class*=" icon-"] {
						@include preserve-px-unit(23px);		// font-size fixe pour les icônes
						@include addTransition();
					}

					&.lien-view-more {
						@extend %bgTtB;

						[class^="icon-"], [class*=" icon-"] {
							color: white;
						}

						&:hover, &:focus {
							body:not(.no-hover) & {

								[class^="icon-"], [class*=" icon-"] {
									color: $c_bg;
								}
							}
						}
					}

					&.lien-download {
						border: 1px solid $c_bg;
						background-color: $c_bg;

						[class^="icon-"], [class*=" icon-"] {
							color: white;
						}

						&:hover, &:focus {
							body:not(.no-hover) & {
								background-color: white;

								[class^="icon-"], [class*=" icon-"] {
									color: $c_bg;
								}
							}
						}
					}

				}
			}
		}

		.title-brochure {
			display: inline-block;
			@include ft($f_hx, 30px, 300, $c_txt, 1);
			margin-top: 25px;

			@include mobile {
				font-size: 15px;
				line-height: 0.8;
			}
		}
	}


	.container-slider{
		&:after{
			content: '';
			display: block;
			clear: both;
		}
		.btn{
			margin-top: 40px;
			margin-right: 0;
			margin-left: auto;
			float: right;
			clear: right;
		}
	}
}