.bloc-images {
	display: flex;
	flex-direction: column;

	.wrapper:first-of-type {
		@include desktop {
			margin: 0;
			width: 100%;
			max-width: none;
		}
	}

	.image-wysiwyg {
		overflow: hidden;
		border-radius: 50px 50px 5px 50px;
		@include mobile{
			border-radius: 30px 30px 5px 30px;
		}
		&:not(.full-image) {
			margin-right: initial;
		}

		figure {
			background: initial;

			// supprime le voile par défaut de la classe image-wysiwyg
			&:after {
				display: none;
			}

			figcaption{
				display: none;
			}
		}

		/*img {
			min-height: 560px;

			@include mobile {
				min-height: 460px;
			}
		}*/
	}

	.img-wrapper {
		position: relative;
		display: block;
	}


	// gradient sur le caption wrapper
	.caption-wrapper {
		@include aspect-ratio(1120, 700);
		position: absolute;     // Surcharge de la position relative définie par défaut dans le mixin d'aspect-ratio
		top: 0;
		left: 0;
		width: 100%;

		@media screen and (max-width: 420px) {
			@include aspect-ratio(380, 480);
			position: absolute;
		}

		&:after {
			content: "";
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			z-index: 1;
			background-image: linear-gradient(to right, rgba($c_dark, 1), rgba($c_dark, 0));
			opacity: 0.5;
			pointer-events: none;
		}
	}

	.image-wysiwyg.alignleft .caption-wrapper {
		&:after {
			background-image: linear-gradient(to right, rgba($c_dark, 1), rgba($c_dark, 0));
		}
	}

	.image-wysiwyg.alignright .caption-wrapper {
		&:after {
			background-image: linear-gradient(to left, rgba($c_dark, 1), rgba($c_dark, 0));
		}
	}

	.image-wysiwyg.aligncenter .caption-wrapper {
		&:after {
			background-image: linear-gradient(to top, rgba($c_dark, 1), rgba($c_dark, 0));
		}
	}



	.container {
		position: relative;
	}

	.nav-buttons {
		position: absolute;
		bottom: 40px;
		right: 40px;
		left: auto;
		z-index: 100;
		width: 100px;
		height: 40px;
		pointer-events: none;

		@include desktop {
			left: 80px;
			right: 80px;
			width: calc(100% - 160px);
		}
		@include tablet-landscape {
			left: 40px;
			right: 40px;
			width: calc(100% - 80px);
		}
		@include mobile {
			display: none;
		}

		button {
			pointer-events: initial;
			border: none;

			&:first-of-type {
				position: absolute;
				left: 0;
			}

			&:last-of-type {
				position: absolute;
				right: 0;
			}
		}
	}

	.nav-dots {
		order: 3;
		justify-content: flex-end;

		button {
			margin-right: 10px;

			&:last-of-type {
				margin: 0;
			}
		}
	}

	&.wrapper:not(.wrapper-small) {
		.th-slider {
			@include desktop {
				width: calc(((100vw - 100%) / 2) + 100%);
			}
			.image-wysiwyg {
				@include desktop {
					width: 100%;
				}
			}
		}
	}

	.th-slider {
		.image-wysiwyg {
			margin-left: ($small-margin / 2);
			margin-right: ($small-margin / 2);
			@include mobile {
				width: calc(100% - 20px);
				margin-left: 5px;
				margin-right: 5px;
			}

			figcaption {
				display: none;
			}
		}
	}
}