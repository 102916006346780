.barre-listing-sit {

	.container {

		button {
			min-width: 118px;
			max-width: 100%;
			margin-left: 10px;
			@include addTransition();

			@include tablet-portrait {
				min-width: auto;
				padding: 0;
			}

			&:first-of-type {
				margin-left: 0;
			}

			span:not(.counter):not(.filter-counter) {
				@include tablet-portrait {
					display: none;
				}
			}
		}

		.resultats {
			font-size: 16px;
			line-height: 1.5;
			font-weight: 400;
			margin-right:auto;

			@include tablet-portrait {
				order: 2;
				font-size:14px;
				margin-left: auto;
			}
		}

		.btn-carte {
			order: 1;
			@include min-tablet-portrait {
				display:none;
			}
		}

		.facettes-wrapper{
			position: relative;
			margin-right: 20px;
			@include tablet-portrait{
				display: none;
			}


			.date-picker-wrapper{
				position: absolute;
				left: 0;
				top: -600% !important;
				margin-right: 0;
				box-shadow: 5px 5px 20px rgba($c_dark, 0.2);
				padding: 20px;

				// règles pour forcer la largeur à rentrer dans l'overlay filtres
				.gap {
					visibility: hidden !important;
				}

				.month-wrapper {
					width: 660px !important;
					display: flex;

					table {
						width: calc(20%);
						float: left;

						.day{
							padding: 8px;
						}
					}
				}
			}
		}

		.btn-filtre {
			order: 3;
		}

		.social-share {
			margin-left:20px;
			order:4;

			@include tablet-portrait {
				display: none;
			}
		}
	}

	&.barre-listing-sit-agenda {
		@include tablet-portrait {
			margin: 0;
		}

		.container {
			position: relative;
			top: 0;
			padding-left: 20px !important;

			@media (max-width: 1130px) {
				padding-right: 80px !important;
			}

			.wrapper-date {
				@include ft($f_txt, $s_p, 300, $c_txt, 1.25);
				position: relative;
				display: flex;
				align-items: center;
				flex-shrink: 0;
				width: auto;
				padding: 20px;
				background-color: $c_white;
				border: 1px solid $c_sub;
				min-height: 60px;
				text-decoration: none;
				max-width: 200px;
				min-width: 150px;
				text-align: center;

				@media (max-width: 1130px) {
					padding: 20px 10px;
				}
				@include tablet-portrait {
					order: 2;
				}

				input {
					@include ft($f_txt, $s_p, 500, $c_txt, 1.25);
					border: none;
					outline: none;
					padding: 0;
					max-width: 95px;

					&::-webkit-inner-spin-button,
					&::-webkit-calendar-picker-indicator {
						display: none;
						-webkit-appearance: none;
					}
				}

				label {
					margin-right: 5px;
					white-space: nowrap;
				}

				[class^="icon-"], [class*=" icon-"] {
					font-size: 20px;
					margin-right: 10px;
				}

				&:first-of-type {
					border-right: 1px dashed $c_sub;
				}

				&:nth-of-type(2) {
					border-left: none;
				}
			}
			.resultats {
				margin-left: auto;
				font-weight: 500;
				max-width: 100px;
				text-align: center;

				@include tablet-portrait {
					order: 3;
					max-width: 100%;
				}
			}

		}

	}
	& + footer {
		padding-bottom: 120px;

		@include tablet-landscape {
			padding-bottom: 100px;
		}
	}
}