// Le positionnement des éléments est fait avec la propriété ordre parce que
// pour l'accessibilité il faut toujours avoir les dots comme premiers éléments dans la structure html,
// donc on est obligé à jouer avec les ordres en css

.bloc-galerie {
	position: relative;
	display: block;

	.galerie-wrapper{
		display: block;
		grid-auto-rows: auto;
		column-count: 3;
		column-gap: 7px;
		@include mobile{
			column-count: 2;
			column-gap: 10px;
		}

		&:after{
			content: '';
			display: block;
			clear: both;
		}
		.item{
			float: none;
			position: relative;
			display: block;
			margin-bottom: 7px;
			border-radius:5px;
			@include mobile{
				margin-bottom: 10px;
			}

			&.zoombox {
				position:relative;
				overflow:hidden;
				&:hover {
					@extend %feuille-pleine;
					&:after {
						visibility: visible;
						opacity:0.75;
						@include addTransition();
					}
				}

				&:after {
					content:"";
				//	transform: rotate(-10deg);
					@include addTransition();
					visibility: hidden;
					opacity:0;
				}
			}
		}
	}
}