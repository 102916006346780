.cover-home {
	position: relative;
	margin-top: 0;
	margin-left: 40px;
	margin-right: 40px;
	margin-bottom: 70px;
	height: 100vh;
	max-height: calc(100vh - 80px);
	width: calc(100% - 80px);
	overflow: hidden;
	border-radius: 5px 5px 50px 5px;


	@extend %feuille-blanche-quadrille;

	@extend %feuille-blanche;

	@extend %feuille-blanche-full;

	&:before {
		top: 141px;
		left: -120px;
		@include mobile {
			display: none;
		}
	}

	&:after {
		bottom: -100px;
		right: -130px;
		@include tablet-portrait {
			bottom: -110px;
			right: -200px;
		}
		@include mobile {
			display: none;
		}
	}


	@include tablet-portrait {
		// Le padding à la même taille que la barre dockée vers le bas de l'écran (à partir de la version tablette)
		max-height: calc(100vh - 140px);
        margin-bottom: 60px;
	}
	@include mobile{
		margin-left: 20px;
		margin-right: 20px;
		max-height: 420px;
        margin-bottom: 40px;
		width: calc(100% - 40px);
	}

	// Les styles suivants vont s'occuper de positionner le composant nav-dots et nav-buttons
	.nav-dots {
		position: absolute;
		bottom: 80px;
		left: calc((100% - 1440px) / 2);
		z-index: 110;
		display: flex;
		align-items: flex-end;
		@media screen and (max-width: 1600px) {
			left: 80px;
		}
		@include tablet-landscape{
			left: 40px;
		}
		@include mobile {
			bottom: 40px;
			left: 20px;
			transform: none;
		}

		button {
			margin-right: 10px;

			&:last-of-type {
				margin-right: 0;
			}
		}
	}

	.nav-buttons {
		position: static;
		height: 0;
		@include mobile {
			display: none;
		}

		button {
			position: absolute;
			top: auto;
			bottom: 80px;
			margin: 0;
			@include mobile{
				display: none;
			}

			&:first-of-type {
				right: 140px;
				margin-left: 0;
				@include tablet-portrait{
					right: 100px;
				}
			}

			&:last-of-type {
				right: 80px;
				margin-right: 0;
				@include tablet-portrait{
					right: 40px;
				}
			}
		}
	}

	.th-slider {
		height: 100%;

		&.scroll-mode {
			padding-left: 0;
			padding-right: 0;
			scroll-padding: 0;
		}
	}

	.image-wysiwyg {
		margin: 0;
		padding: 0;
		height: 100%;
		overflow: hidden;

		&:first-of-type {
			padding-left: 0;
		}

		figure {
			background:none;
			height: 100%;
			&:before {
				content: "";
				display: block;
				width: 100%;
				height: 40%;
				background: linear-gradient(0deg, rgba(2,19,46,0) 0%, rgba(2,19,46,1) 100%);
				opacity: .5;
				z-index: 1;
				position: absolute;
				left: 0;
				top: 0;
				pointer-events: none;
			}
		}

		.caption {
			justify-content: center;
			top: auto;
			transform:none;
			bottom: 130px;
			@include mobile {
				left: 0;
				bottom: 90px;
				width: calc(100% - 40px);
			}

			> * {
				color: $c_white;
			}

			p {
				font-weight: 400;
				margin-bottom: 15px;
			}

			.btn {
				width: auto;
				align-self: flex-start;
			}


		}
	}
}