
.overlay-favoris {
  max-width: none;

  .overlay-favoris__inner {
    display: flex;

    > * {
      flex-grow: 1;
    }

    @include tablet_small {
      .wrapper-map {
        display: none;
      }
      .wrapper-cards {
        width: 100%;
        max-width: none;
      }
    }
  }

  .wrapper-cards {
    position: relative;
    min-height: 100vh;
    max-width: 500px;
    width: 100%;

    .card {

      @include min-mobile {
        min-height: 140px;

        .image {
          width: 160px;
          height: 140px;
        }
      }


      .image {
        overflow: initial;

        img, .no-photo {
          border-radius: 5px 5px 5px 50px;
        }
      }

      .btn-favoris {
        display: none;

        &:after {
          content: none;
        }
      }

      .btn-suppr {
        display: flex;
        box-shadow: none;
        position: absolute;
        left: 0;
        padding: 0;
        transform: translate(-50%, -50%);
        top: 50%;
        z-index: 5;
        @extend .btn-close-plein;
        width: 25px;
        height: 25px;
        font-size: 10px;

        &:after {
          background-image: none;
        }

        @include mobile {
          width: 20px;
          height: 20px;
        }
      }

      &.agenda {
        @include mobile {
          .image {
            min-height: 120px;
          }
        }
      }
    }
  }

  .wrapper-map {
    display: flex;
    flex-direction: column;
    height: 100vh;

    .maps {
      flex-grow: 1;
    }

    .card .btn-favoris {
      display: none;
    }
  }

  .scroller {
    height: 100vh;
    padding: 35px 40px 140px;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    @include mobile {
      padding: 30px 20px 130px;
    }

    .nb-items {
      font-weight: 300;
      color: rgba($c_txt, 0.30);
      font-size: 30px;
      margin-left: 5px;

      &.empty {
        display: none;
      }
    }
  }

  .empty-panier {
    @include ft($f_txt, $s_p, 500, $c_txt, 24px);
    position: absolute;
    top: 0;
    width: 50%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    left: 0;
    padding-left: 40px;
    cursor: pointer;
    @include addTransition();

    @include mobile {
      font-size: 0px;
      padding-left: 20px;
      max-width: 40%;
      text-align: left;
    }

    &:before {
      border: 1px solid $c_bg;
      border-radius: 50%;
      content: "\e928";
      padding: 10px;
      @include icomoon();
      font-size: 8px;
      display: block;
      color: $c_txt;
      margin-right: 10px;
      @include addTransition();
    }

    &:hover, &:focus {
      body:not(.no-hover) & {
        color: $c_sub;

        &:before {
          color: $c_sub;
          border: 1px solid $c_sub;
        }
      }
    }
  }


  // popup du formulaire
  .form-popup {
    display: none;
    position: absolute;
    z-index: 1000000;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: calc(100% - 40px);
    max-width: 500px;
    padding: 60px 60px 60px;
    background-color: $c_white;

    pointer-events: initial;
    box-shadow: 0 0 60px rgba(0, 0, 0, 0.2);

    @include mobile {
      top: 0;
      left: 0;
      transform: initial;
      z-index: 8000;
      width: 100%;
      height: calc(100vh - 80px);
      padding: 60px 20px 60px;
      border-radius: initial;
      box-shadow: initial;
    }

    &.open {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    .close {
      @extend .btn-close;
      position: absolute;
      top: 20px;
      right: 20px;
      left: auto;
      transform: none;
      @include addTransition();

      &:hover {
        cursor: pointer;
      }
    }
  }
}


