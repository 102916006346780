// ==============================================

//        IMPORT DES FONTS

// ==============================================

// Version bold
@font-face {
    font-family: 'Rainier';
    src: url('../fonts/RainierWest/RainierWest300.woff2') format('woff2'),
    url('../fonts/RainierWest/RainierWest300.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

// Version regular
@font-face {
    font-family: 'Rainier';
    src: url('../fonts/RainierWest/RainierWest300.woff2') format('woff2'),
    url('../fonts/RainierWest/RainierWest300.woff') format('woff');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@import url("https://use.typekit.net/qdh8mij.css");