.single-sit {
	.bloc {
		margin-bottom:$bloc-spacing;
		@include mobile {
			margin-bottom:50px;
		}
	}
	.view-more-active {
		.text {
			position: relative;
			max-height: 120px;
			overflow-y: hidden;
			@include addTransition();

			&:before {
				content: '';
				display: block;
				width: 100%;
				height: 60px;
				position: absolute;
				bottom: 0;
				left: 0;
				right: 0;
				background: linear-gradient(to top, rgba(255, 255, 255, 0.99) 0%, rgba(255, 255, 255, 0.79) 20%, rgba(255, 255, 255, 0.59) 40%, rgba(255, 255, 255, 0.39) 60%, rgba(255, 255, 255, 0.19) 80%, rgba(255, 255, 255, 0.01) 100%);
				z-index: 1;
				opacity: 1;
			}

			&.toggle-height {
				max-height: none;

				&:before {
					opacity: 0;
				}

				& + .btn-view-more {
					&:after {
						transform: translate(-50%, -50%) rotate(180deg);
					}
				}
			}
		}

		.btn-view-more {
			display: block;
		}
	}

	/* Styles générales à tous les types de bloc sit */

	.meta-wrapper {
		margin: 0;

		.meta {
			display: flex;
			align-items: center;
			font-size:16px;
			max-height:initial;
			font-weight: 300;

			strong {
				font-weight: 700;
			}

			[class^="icon-"], [class*=" icon-"] {
				color:$c_main;
				& + span {
					margin-left: 10px;
				}
			}
		}
	}

	.small-title {
		display: block;
		font-size: 30px;
		margin-bottom:0;
		line-height: 1;

		& + span {
			display: block;
			margin-top: 10px;
		}
	}

	.texte-supplementaire {
		font-style: italic;
	}

	.elements-separes {
		li {
			position: relative;
			display: flex;
			align-items: center;
			justify-content: space-between;

			&:after {
				content: "";
				display: block;
				width: 100%;
				height: 1px;
				background-color:  rgba(#133E4A,.08);
				position: absolute;
				top: -20px;
				left: 0;
			}

			&:nth-of-type(1), &:nth-of-type(2) {
				&:after {
					display: none;

					@include mobile {
						display: block;
					}
				}
			}

			&:nth-of-type(1) {
				&:after {
					@include mobile {
						display: none;
					}
				}
			}
		}

		.content-container {
			display:grid;
		}

		.value {
			font-size:16px;
			line-height:14px;
			font-family: $f_txt;
		}
	}

	/*********
	**** Styles pour les différents parties SIT dans la page
	**********/

	/* Sit à propos */

	.sit-a-propos {

		.labels {
			display: flex;
			align-items: flex-start;
			flex-wrap: wrap;

			> figure {
				margin-right: $small-margin;
				margin-top: 20px;

				@include mobile {
					width: 90px;
				}
			}


			// titre label
			.title-2 {
				display: block;
				font-size: 18px;
				line-height: 1;
				font-weight: 600;
				width: 100%;
			}
		}
	}


	/* sit info gallerie */
	.sit-gallery {
		.wrapper {
			&.scroll-mode-parent {
				@include desktop {
					margin: 0;
					width: 100%;
					max-width: none;
				}
			}
		}

		.nav-buttons {
			margin-bottom:25px;

			@include mobile {
				display: none;
			}
		}

		.slide {
			margin-right: 20px;
			max-width: 100%;
			position: relative;
			cursor: default;

			@include mobile {
				max-width: calc(100% - 20px);
			}

			figure {
				display: table;
				background-color: transparent;
				border-radius:50px;

				img {
					border-radius:50px 50px 0 50px;
					height: 588px;
					object-fit: cover;

					@include mobile {
						height: 240px;
						width: auto;
					}
				}
			}
		}
	}


	/* Sit info pratiques */

	.sit-info-pratiques {
		.title {
			& + ul {
				margin-top: 40px;

			}

			.small-title {
				& + .elements-separes {
					@include mobile {
						margin-top: 40px;
					}
				}
			}
		}

		h2 + h3 {
			margin-top:20px;
		}


		//Infos sup
		.infos-sup{
			* > {
				margin-left:0;
			}

			li {
				margin-top:28px !important;
			}

			span {
				margin-right: 12px;
				top: 1px !important;
				color: $c_main;
			}
		}

		ul {
			& + .small-title {
				margin-top: 70px;
				@include mobile {
					margin-top: 40px;
				}
			}

			&:not(:first-of-type) {
				margin-top: 0;
			}
		}

		.small-title {
			@include mobile {
				margin-bottom: 25px;
			}
		}

	}

	/* Sit a propos - info pratiques (règles communes aux deux afin d'éviter la répétition du css) */
	.sit-a-propos, .sit-info-pratiques, .sit-tarifs {
		ul:not(.meta-wrapper) {
			display: flex;
			flex-wrap: wrap;
			margin: 0;
			width: 100%;

			> * {
				width: calc(50% - 20px);
				padding: 0;
				font-size: 16px;
				line-height: 1.42;
				margin-top: 40px;
				flex-shrink: 0;
				align-self: flex-start;

				@include mobile {
					width: 100%;
				}

				&:first-of-type {
					@include mobile {
						margin-top: 0
					}
				}

				&:nth-of-type(even) {
					margin-left: 20px;

					@include mobile {
						margin-left: 0;
					}
				}

				&:nth-of-type(odd) {
					margin-right: 20px;

					@include mobile {
						margin-right: 0;
					}
				}

				&:before {
					display: none;
				}

				[class^="icon-"], [class*=" icon-"] {
					position: relative;
					top: 3px;
				}
			}
		}
	}

	/* Sit tarifs */

	.sit-tarifs {
		.elements-separes {
			flex-direction: column;

			li {
				width: 100%;
				max-width: none;
				margin-right: 0 !important;

				&:nth-of-type(even) {
					margin-left: 0;
				}

				&:nth-of-type(2) {
					&:after {
						display: block;
					}
				}
			}
		}

		.title {
			& + .meta-wrapper {
				margin-top: 30px;
			}
		}

		.small-title {
			@include mobile {
				margin-bottom: 25px;
			}
		}

		.meta-wrapper {
			.meta {
				border: none;
				margin-bottom: 0;
				padding: 0;
			}
		}

		.container {
			display: flex;
			align-items: flex-start;
			flex-wrap: wrap;
			margin-top: 40px;

			> .col {
				width: calc(50% - 20px);

				@include mobile {
					width: 100%;
				}

				&:nth-of-type(odd) {
					margin-right: 20px;

					@include mobile {
						margin-right: 0;
					}
				}

				&:nth-of-type(even) {
					margin-left: 20px;

					@include mobile {
						margin-left: 0;
					}
				}

				.single-meta {
					display: inline-block;
					border: 1px solid $c_sub;
					padding: 7px 10px;
					font-size: 12px;
					font-weight: 700;

					& + .text {
						margin-top: 15px;
					}
				}

				.title-section {
					@include ft($f_txt, $s_medium, 300, $c_txt, 1);
					text-transform: uppercase;

					& + * {
						margin-top: 30px;
					}
				}

				& + .texte-supplementaire {
					margin-top: 40px;
				}
			}
		}
	}

	/* Sit horaires */

	.sit-horaires {
		position: relative;

		&.th-slider-wrapper {
			@include min-mobile {
				padding-left: 40px;
				padding-right: 40px;
				box-sizing: content-box;
			}
		}

		.title {
			@include min-mobile {
				margin-top: 0;
				margin-bottom: 39px;
			}
		}

		.btn-primary {
			position: absolute;
			right: 0;
			top: 40px;
			margin-top: 0;

			@include mobile {
				position: static;
				margin-top: 0;
			}
		}

		.container {
			overflow: hidden;
		}

		.nav-buttons {
			width: calc(100% + 80px);
			position: relative;
			top: 40px;
			left: -40px;
			display: flex;
			justify-content: space-between;

			button {
				width: 30px;
				height: 30px;
				border: none;

				&:before {
					font-size: 10px;
				}

				&:hover, &:focus {
					body:not(.no-hover) & {
						color: $c_greydark;
					}
				}

			}
		}

		.th-slider {
			.slide {
				width: 100%;
				display: flex;
				flex-wrap: wrap;
				align-items: flex-start;
				margin-right: 20px;

				.col {
					flex-grow: 1;

					@include mobile {
						margin-top: 40px;

						&:first-of-type {
							margin-top: 0;
						}
					}

					.date {
						display: block;
						font-size: 16px;
						line-height: 24px;
						font-weight: 500;
						margin-bottom: 40px;
					}

					ul {
						margin: 0;
					//	margin-top: -40px;

						li {
							position: relative;
							padding: 0;
							display: flex;
							align-items: center;
							justify-content: space-between;
							margin-top: 40px;
							max-width: none;

							&:after {
								content: "";
								display: block;
								width: 100%;
								height: 1px;
								background-color: rgba($c_bg, 0.08);
								position: absolute;
								bottom: -20px;
								left: 0;
							}

							&:last-of-type {
								&:after {
									display: none;
								}
							}

							&:before {
								display: none;
							}
						}
					}

					& + .col {
						@include min-mobile {
							margin-left: 40px;
						}
					}
				}
			}

			& + .texte-supplementaire {
				margin-top: 40px;
			}
		}
	}

	/* Sit documentation */

	.sit-documentation {
		.doc-info {
			position: relative;
			display: flex;
			justify-content: center;
			flex-direction: column;
			padding: 20px 80px 20px 40px;
			width: 100%;
			min-height: 85px;
			text-decoration: none !important;
			margin-bottom: 15px;
			border: 1px solid $c_border;
			@include addTransition();

			@include mobile {
				padding: 20px 60px 20px 20px;
				margin-bottom: 15px;
				min-height: 65px;
			}

			&:after {
				content: "\e929";
				@include icomoon();
				font-size: 18px;
				color: $c_main;
				display: block;
				position: absolute;
				right: 40px;
				@include addTransition();

				@include mobile {
					right: 20px;
				}
			}

			.doc-type-weight {
				font-size: 14px;
				line-height: 24px;
				font-weight: 300;
				@include addTransition();

				@include mobile {
					font-size: 12px;

					& + .title {
						margin-top: 5px;
					}
				}

				.uppercase {
					text-transform: uppercase;
				}
			}

			.title {
				font-size: 16px;
				font-weight: 700;
				line-height: 24px;

				@include mobile {
					font-size: 15px;
				}
			}

			&:hover, &:focus {
				body:not(.no-hover) & {
					background-color: $c_dark;

					&:after {
						color: $c_white;
					}

					.doc-type-weight,
					.title {
						color: $c_white;
					}
				}
			}
		}
	}

}