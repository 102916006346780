.barre-single-sit {

  .container {

    /*	.content {
            max-width: 400px;
        } */

    .content, .btn-map-sit {
      @include tablet-portrait {
        display: none;
      }
    }

    .classement {
      margin-left: 8px;
      color: $c_sub;
    }

    .content-container {
      display: flex;
      align-items: center;
    }

    .surtitre {
      @include ft($f_txt, 16px, 500, $c_sub, 24px);
    }

    .title {
      display: block;
      position: relative;
      margin-top: 0;
      @include ft($f_txt, 20px, 600, $c_txt, 26px);
      @include ellipsis(1, 26px);
    }

    .location {
      margin-top: 5px;
      font-size: 14px;
      line-height: 14px;

      &:before {
        top: 1px;
      }
    }

    .btn-map-sit {
      & + .content {
        margin-left: 20px;
      }
    }

    figure {
      flex-shrink: 0;
    }
  }

  .right-side {
    margin-left: auto;
    display: flex;
    align-items: center;
  }

  .liens-rapides {
    display: flex;
    align-items: center;
    margin-left: auto;
    margin-right: 20px;
    font-size: 0;

    @include tablet-portrait {
      //	margin-left: -5px;
      & + * {
        //		margin-left: auto;
      }
    }

    @include mobile {
      //	margin-left: 0;
      margin-right: 5px;
    }

    .contact {
      font-size: 0;
      white-space: nowrap;

      a {
        padding: 0;
        width: 40px;
        height: 40px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        font-size: 16px;
        margin-right: 10px;
        @include mobile {
          margin-right: 5px;
          width: 30px;
          height: 30px;
          font-size: 13px;
        }

        span {
          transition: none;
        }
      }

      + a {
        margin-left: 20px;
        height: 40px;
        @include mobile {
          margin-left: 10px;
        }
      }
    }

    li {
      margin-right: 20px;
      max-width: none;
      @include addTransition();
      @include tablet-portrait {
        width: 40px;
      }
      @include mobile {
        width: 30px;
        height: 30px;
      }

      &:last-of-type {
        margin-right: 0;
      }

      &:before {
        font-size: 20px;
        z-index: -1;

        @include mobile {
          font-size: 16px;
        }
      }

      a {
        display: block;
        width: 100%;
        height: 100%;

        &.ga-event-overlay-mail {
          .tooltiptext {
            width: auto;
            margin-left: -77px;
          }
        }
      }

      &[data-icon="appel"] {
        &:before {
          content: "\e901";
        }
      }

      &[data-icon="email"] {
        &:before {
          content: "\e900";
        }
      }

      &[data-icon="lien"] {
        &:before {
          content: "\e902";
        }

        @include tablet-portrait {
          display: none;
        }
      }

      .gtm-event-mail {
        .tooltiptext {
          margin-left: -76px;
          width: initial;
        }
      }

      .tooltiptext {
        top: calc(-100% + 12px);
        margin-left: -54px;
        padding: 5px 10px;
      }

      &.reservation {
        margin-right: 0;
        width: auto;
      }

      &:hover, &:focus {
        body:not(.no-hover) & {
          .tooltiptext {
            visibility: visible;
          }
        }
      }
    }

    /* On ajoute ces styles pour bouger les éléments à droite (l'objectif et de laisser un grand padding au bouton favoris) */
    & ~ * {
      @include tablet-landscape {
        position: relative;
      }
    }
  }

  .btn {
    @include mobile {
      max-width: 102px;
      min-width: auto;
      height: 40px;
      min-height: auto;
    }
  }

  & + footer {
    padding-bottom: 120px;

    @include tablet-landscape {
      padding-bottom: 100px;
    }
  }

  &.barre-sigle-sit-agenda {
    .surtitre {
      color: $c_txt;
    }
  }

  &.barre-sigle-sit-post {
    .surtitre {
      color: $c_txt;
    }
  }
}