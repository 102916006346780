// Styles pour les headings
.header-edito {
    @include mobile{
        margin-bottom: 40px;
    }
	.general-container {
		position: relative;
		height: calc(100vh - 80px);
		max-height: -webkit-fill-available;
		overflow: hidden;

		@include tablet-landscape {
			height: 800px;
		}
		@include mobile {
			height: 500px;
		}

		.video-couverture {
			height: 100%;

			video {
				min-width: 100%;
				min-height: 100%;
				width: auto;
				height: auto;
			}
		}
	}

	.container-info-supp {
		.author {
			figure {
				width: 60px;
				height: 60px;

				img {
					height: auto;
				}
			}

			&:not(:only-child) {
				margin-top: 20px;
			}
		}
	}

	.header-edito-intro {
		margin-top: 30px;
		color:white;

		p {
			font-size: 16px;
			line-height: 24px;
			font-weight: 300;
			color: white;
		}
	}


	figure {
		height: auto;
		width: auto;
	}

	// Styles générales aux deux types de header
	.header-edito-wrapper {
		display: flex;
		flex-direction: column;
		width: auto;

		// Wrapper custom afin d'éviter d'avoir un header en format small dans les grands écrans
		&.wrapper-custom {
			position: relative;
			margin: 0 auto;
			max-width: $wrapper-width;

			@media screen and (max-width: $wrapper-width+$border-gutter+$border-gutter+0px) {
				margin: 0 $border-gutter;
			}
			@include wide {
				max-width: $wrapper-width !important;
			}
			@media screen and (min-width: $wrapper-small-width+$border-gutter+$border-gutter+$grid-gap) and (max-width: map-get($breakpoints, 'wide')) {
				max-width: $wrapper-small-width !important;
				margin: 0 auto !important;
			}
			@include tablet-landscape {
				margin-left: 40px;
				margin-right: 40px;
			}
			// Breakpoint mobile, on met les marge de l'écran pour tout
			@include mobile {
				margin-left: 20px;
				margin-right: 20px;
			}
		}

		> * {
			color:$c_white;
		}

		.surtitre {
			color:$c_white;
		 }

		> .container {
			display: flex;
			align-items: center;
			margin-bottom: 7px;

			.surtitre {
				margin: 0;
				color: white;
			}

			.classement {
				margin-left: 10px;
				color:$c_white;
			}
		}

		.meta-wrapper {
			.meta {
				border:1px solid $c_white;
				color:$c_white;
				padding: 4px 9px 7px;
				font-weight:600;
				text-transform:uppercase;
			}
		}

		.location{
			color: white;
			&:before{
				color: white;
			}
		}
	}

	&:not(.header-full-image) {
		margin: 0 40px 80px;
		background-color: $c_bg;
		padding: 160px 80px 80px;
		overflow: hidden;
		border-radius: 5px 5px 50px 5px;
		@media screen and (max-width: 1160px){
			padding: 160px 40px 80px;
		}
		@include tablet-landscape{
			padding: 145px 40px 40px;
		}
		@include mobile{
			margin: 0 20px 40px;
			padding: 145px 25px 40px;
		}
		.general-container {
			height: auto;

			@include mobile {
				padding-top: 40px;
			}

			> figure {
				display: none;
			}
		}

		.wrapper-custom {
			position: relative;
			@media screen and (max-width: 1100px){
				margin-left: 0;
				margin-right: 0;
			}
			h1 {
				@include min-tablet-portrait {
					max-width: 80%;
				}
			}

			#breadcrumb {
				margin-top:30px;
				margin-bottom: 0;
				@include mobile{
					display: none;
				}
				a, span{
					color: white;
				}
				li:after{
					color: white;
				}
			}

			.container-info-supp {
				@include min-tablet-portrait {
					position: absolute;
					bottom: 5px;
					right: 0;
				}
				@include tablet-portrait {
					margin-left: 0;
				}

				.surtitre, .price {
					@include tablet-portrait {
						text-align: left;
					}
				}

				.surtitre {
					@include tablet-portrait {
						margin-top: 20px;
					}
				}
			}
		}

		& + .content {
			top: auto;

			.barre-single-sit {
				height: 0;
			}
		}
	}

	// Styles pour la versio full-screen du header
	&.header-full-image {
		.general-container {
			position: relative;
			display: block;
			margin: 0 40px 80px;
			height: calc(100vh - 130px);  // 100px pour la barre sticky  + 40px pour le margin - 10px parce que
										 // la barre sticky chevauche l'image de fond
			overflow: hidden;
			border-radius: 5px 5px 50px 5px;
		/*	&:after {
				content: "";
				display: block;
				width: 61.718%;
				height: calc(100% - 160px);
				background-image: url("../images/deco/radial-gradient-bottom-big.svg");
				background-position: right bottom;
				background-repeat: no-repeat;
				position: absolute;
				right: 0;
				bottom: 0;
				z-index: 1;
				pointer-events: none;
				opacity: 0.40;

				@include mobile {
					width: 100%;
				}
			} */

			@include mobile {
				height: 420px;
				margin: 20px;
			}

			> figure {
				position: relative;
				display: block;
				height: 100%;

				&:before {
					content: "";
					display: block;
					width: 100%;
					height: 40%;
					background: linear-gradient(0deg, rgba(2,19,46,0) 0%, rgba(2,19,46,1) 100%);
					opacity: .5;
					z-index: 1;
					position: absolute;
					left: 0;
					top: 0;
					pointer-events: none;
				}

				&:after{
					content: "";
					display: block;
					width: 80%;
					height: 100%;
					background: linear-gradient(-90deg, rgba(0,0,0,0), #000);
					opacity: 0.50;
					position: absolute;
					top: 0;
					left: -20%;
					bottom: 0;
					z-index: 2;
					pointer-events: none;
				}
			}

			.credits {
				display:none;
			}
		}

		& + .content {
			.barre-single-sit {
				& + .bloc {
					padding-top: 60px;
				}
			}

		/*	> div:last-of-type {
				@include min-tablet-landscape {
					padding-bottom: 150px;
				}
			} */
		}

		.header-edito-wrapper {
			position: absolute;
			top: auto;
			bottom: 80px;
			right: 0;
			left: 0;
			width: auto;
			z-index: 10;
			@include tablet-portrait{
				bottom: 45px;
			}
			@include mobile{
				bottom: 40px;
			}

			> * {
				@include min-tablet-portrait {
					max-width: 60%;
				}
			}

			> .container {
				.surtitre {
					color: $c_white;
				}

				[class^="icon-"], [class*=" icon-"] {
					color: $c_white;
				}
			}

			h1 {
				color: $c_white;
			}

			#breadcrumb {
				color: $c_white;
				margin-top: 30px;

				@include mobile {
					margin-top: 20px;
				}

				li {
					&:hover, &:focus {
						body:not(.no-hover) & {
							a {
								color: $c_white;
							}
						}
					}

					&:last-of-type {
						a {
							color: $c_sub;
						}
					}
				}
			}
		}

		#breadcrumb {
			@include mobile{
				display: none;
			}
			li {
				color: $c_white;

				&:after {
					color: $c_white;
				}

				a, span {
					color: $c_white !important;
				}

				&:last-of-type {
					&:not(:only-child) {
						a {
							color: rgba($c_white, 0.50) !important;
						}
					}
				}
			}
		}

		.container-info-supp {
			@include tablet-landscape {
				margin-top: -40px;
			}
			@include tablet-portrait {
				margin-top: 0;
			}

			.author {
				.author-info {
					color: $c_white;

					.author-name {
						color: $c_white;
					}

					& + figure {
						margin-left: 15px;

						@include tablet-portrait {
							margin-left: 0;
						}
					}
				}
			}

			> * {
				color: $c_white;
			}
		}


	}
}