.page-listing {
	header {
		& + * {
			margin-top: 80px;
			@include tablet-small {
				margin-top: 50px;
			}
		}
	}

	.small-header {
		& + header {
			margin-bottom: 40px !important;
		}
	}


	.barre-listing-sit {
		.container {
			padding-top: 20px;
			padding-bottom: 20px;
			padding-left: 40px;
			padding-right: 100px;
			@include mobile {
				padding-right: 40px;
				margin-left: 0;
				margin-right: 0;
			}
		}
	}


	.js-nbresults_founded {
		display:none;
	}

	.listing-sans-carte {
		position: relative;
		.wrapper-cards {
			margin:0 auto;
			margin-left: -10px;
			margin-right: -10px;
			display: block;


			&:after{
				content: '';
				display: block;
				clear: both;
			}

			.listing-card-resultat {
				display: flex;
				align-items: baseline;
				flex-wrap: wrap;
				width:100%;
				@include min-mobile {
					margin-top: -100px;
				}
			}

			.card {
				float: left;
				box-sizing: border-box;
				width: calc(33.3333% - 20px);
				margin: 10px;
				align-self: flex-start;
				&:nth-child(3n+1){
					clear: left;
				}
				@include tablet_portrait {
					width: calc(50% - 20px);
					&:nth-child(3n+1){
						clear: none;
					}
					&:nth-child(2n+1){
						clear: left;
					}
				}
				@include mobile {
					@include card-horizontale();
					width: 100%;
					margin-top: 0;
				}
			}

			.listing-no-results {
				width: 100%;
			}

		}
	}

	.listing-avec-carte {
		overflow: visible;
		// Pour le calcul du padding-right on doit soustraire 80px - 10px (10px c'est le padding que chaque card à vers la droite°
		padding-right: 70px;

		@include tablet-landscape {
			padding-right: 30px;
		}
		@include tablet-portrait {
			padding-right: 40px;
		}
		@include mobile {
			padding-right: 20px;
			padding-left: 20px;
		}

		.wrapper-content {
			position: relative;
			display: flex;

			.wrapper-sticky {
				position: sticky;
				bottom: 0;
				top: 0;
				flex-shrink: 0;
				width: 47.46%;
				padding-right: 30px;
				height: calc(100vh);

				@include mobile {
					display: none;
				}

				.maps {
					height: 100%;
				}
			}

			.wrapper-cards {
				flex-grow: 1;
				position: relative;
				display: flex;
				flex-wrap: wrap;
				min-height: calc(100vh);

				@include min-tablet-portrait {
					margin-top: -$big-margin;
				}

				@include tablet-portrait {
					margin-top: 0;
				}

				> * {
					@include min-tablet-portrait {
						margin-top: $big-margin;
						width: calc(50% - 20px);
						padding-left: 10px;
						padding-right: 10px;
					}
					@include min-wide {
						width: calc(33% - 20px);
						padding-left: 10px;
						padding-right: 10px;
					}
					@media (min-width: 2300px) {
						width: calc(25% - 20px);
					}
					@include mobile {
						margin-top: 0;
					}

				}
			}
		}

		&.cards-deux-colonnes {
			.wrapper-content {
				.wrapper-sticky {
					@media (min-width: 1025px) {
						width: 45%;
					}
				}

				.wrapper-cards {
					max-width: 1120px;
					margin-left:auto;
					margin-right:auto;

					> * {
						width: calc(50% - 20px);
						padding-top: 0;
						padding-bottom: 0;
						//padding-right: 10px;

						@include tablet-portrait {
							width: 100%;
							@include card-horizontale();
						}
					}
				}
			}
		}
	}

	.listing-agenda {
		@extend %bgBtT;
		@extend %patternBG-top;
		@extend %patternBG-bottom;
		padding: 100px 0;
		@for $i from 0 through 3 {
			#onglet-date-group-#{$i}:checked {
				& ~ .onglets {
					label[for='onglet-date-group-#{$i}'] {
						background-color: $c_dark;
						color: $c_white;
					}
				}

				& ~ #container-listing-agenda-#{$i} {
					display: block;
				}
			}
		}

		> input {
			display: none;
		}

		.onglets-wrapper {
			display: none;
		}

		.onglets {
			display: flex;
			justify-content: flex-end;

			@include tablet-portrait {
				justify-content: flex-start;
			}
			@include mobile {
				flex-wrap: wrap;
			}

			label {
				display: inline-block;
				width: auto;
				@include ft($f_txt, $s_p, 500, $c_txt, 1.25);
				padding: 20px;
				background-color: $c_white;
				border: 1px solid $c_sub;
				min-height: 60px;
				text-decoration: none;
				max-width: 100%;
				min-width: 150px;
				text-align: center;
				margin-right: 10px;
				@include addTransition();

				@include mobile {
					min-height: 50px;
					padding: 15px;
					font-size: 14px;
					line-height: 1.28;
				}
				@include mobile-small {
					padding: 12px 10px;
					min-height: 40px;
				}

				&:nth-of-type(3) {
					@include mobile {
						margin-top: 10px;
					}
				}

				&:last-of-type {
					margin-right: 0;
				}

				&:hover, &:focus {
					body:not(.no-hover) & {
						cursor: pointer;
						color: $c_white;
						background-color: $c_dark;
						border-color: transparent;
					}
				}
			}
		}

		.onglets-wrapper {
			margin-top: 20px;
		}

		.day-date {
			@include ft($f_txt, $s_small_title, 700, $c_txt, 2.5);
			position: sticky;
			top: 0px;
			display: block;
			background-color: $c_white;
			width: 100%;
			height: auto;
			z-index: 10;
			border-bottom: 1px solid $c_sub;
			margin-bottom: 30px;
			margin-top: 100px;

			@include tablet-portrait {
				margin-top: 20px;
			}
			@include mobile {
				margin-top: 30px;
			}

			&:after {
				content: "";
				display: block;
				width: 100%;
				height: 20px;
				background: linear-gradient(0deg, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1));
				position: absolute;
				bottom: -20px;
				left: 0;
				right: 0;
			}
		}

		.wrapper-cards {
			display: flex;
			align-items: flex-start;
			flex-wrap: wrap;
			margin-top: -40px;

			@include mobile {
				margin-top: 0;
			}

			.card {
				@include card-horizontale();
				@include card-agenda-white();
				position: relative;
				width: calc(50% - 20px);
				padding: 0;
				margin-top: 40px;

				&:before {
					content: "";
					display: block;
					width: calc(100% + 20px);
					height: calc(100% + 20px);
					background-color: $c_white;
					position: absolute;
					left: -10px;
					right: -10px;
					bottom: -10px;
					top: -10px;
					opacity: 0;
					z-index: -1;
					@include shadow();
					@include addTransition();
				}

				&:nth-of-type(odd) {
					margin-right: 40px;

					@include tablet-portrait {
						margin-right: 0;
					}
				}

				@include tablet-portrait {
					width: 100%;
				}
				@include mobile {
					margin-top: 0;
				}

				.image {
					@include min-mobile {
						width: 160px;
						height: 160px;
					}

					.date-event {
						display: none;
					}
				}
				.caption .title-card{
					font-size: 30px;
					line-height: 35px;
				}

				&:hover, &:focus {
					body:not(.no-hover) & {
						&:before {
							opacity: 1;
						}
					}
				}
			}
		}

		#pagination{
			background-color: transparent;
			span, a{
				color: white;
			}
			a:hover{
				body:not(.no-hover) & {
					color: $c_bg;
				}
			}
			.btn-square{
				background-color: transparent;
				&:before,&:after{
					color: white;
				}

				&:hover{
					body:not(.no-hover) & {
						&:before,&:after{
							color: $c_bg;
						}
					}
				}
			}
		}

		.wrapper > p {
			text-align: center;
			color: white;
			font-weight: bold;
			font-size: 18px;
			line-height: 24px;
		}

		> .wrapper {
			& + .wrapper {
				margin-top: 40px;
			}
		}
	}
}