// ==============================================

//        VARIABLES SASS & CSS

// ==============================================


// Fonts
// ==========================================================================
$f_hx: 'Rainier', sans-serif;
$f_txt: sofia-pro, sans-serif;
$f_spe: sofia-pro, sans-serif;

// Colors
// ================================================ ==========================
$c_main: #BCCF00;
$c_sub: #36B6AB;
$c_grey: #333333;
$c_txt: #02132E;
$c_light: #F5F5F5;
$c_dark: #000000;
$c_greydark: #707070;
$c_white: #FFFFFF;
$c_red: #F21D4E;
$c_error: #DC3953;
$c_green: #00C754;
$c_bg: #02132E;
$c_bg-light: #EBF8F7;

$c_border: rgba(#133E4A,.2);

// Sizes
// ==========================================================================
$s_h1: 70px;
$s_h2: 55px;
$s_h3: 30px;
$s_small_title: 20px;
$s_medium: 18px;
$s_p: 16px;
$s_link: 14px;
$s_link_small: 12px;

// Gestion des valeurs des z-index
// ==========================================================================
$z-layers: (
    'overlay' : 200,
    'overlay-shadow': 100,
	'strate-info' : 80,
    'barre-navigation': 70,
    'barre-sticky' : 70,
	'pagination': 50,
    'couverture' : 30,
    'btn-slider' : 20,
    'credits-image' : 10,
    'default' : 1,
    'below' : -1,
);


// Width / Height / Spacing
// ==========================================================================
$small-margin: 20px;
$big-margin: 40px;

// Wrappers
// ==========================================================================

$wrapper-width            : 1120px;     // /!\ anciennement $max-width, taille par défaut quand classe .wrapper seule
$wrapper-medium-width     : 1080px;		// /!\ anciennement $grid-medium
$wrapper-small-width      : 900px;		// /!\ anciennement $grid-small
$wrapper-extra-small-width: 710px;		// /!\ anciennement $grid-extra-small
$wrapper-large-width      : 1440px;		// /!\ anciennement $max-width-large ($max-width-large: 1600px - $border-gutter - $border-gutter;)



// ---- nouvelles variables pour gérer le layout général -----

$border-gutter: 80px;       // marges gauche et droite minimum autour des wrappers
$border-gutter-tablet: 40px;
$border-gutter-mobile: 20px;

$grid-gap: 40px;			 // /!\ anciennement $content-gutter
$grid-gap-small: 20px;
$grid-gap-mobile: 20px;

$section-spacing: 80px;
$section-spacing-mobile: 40px;

$bloc-spacing: 80px;		// /!\ anciennement $content-spacing
$bloc-spacing-mobile: 40px;

$strate-spacing: 80px;
$strate-spacing-mobile: 50px;

$header-height: 60px;
$header-height-mobile: 60px;

$toolbar-height: 70px;
$toolbar-height-mobile: 60px;



// Breakpoints
// ==========================================================================

$breakpoints: (
	mobile-small    : 320px,
	mobile          : 599px,
	tablet-small    : 768px,
	tablet-portrait : 980px,
	tablet-landscape: 1024px,
	desktop         : 1280px,
	wide            : 1600px
);

// Breakpoints (Ancien système sans map)
// ==========================================================================
$breakpoint_mo_small: 320px;
$breakpoint_mo: 599px;
$breakpoint_tablet-small: 768px;
$breakpoint_portrait: 980px;
$breakpoint_paysage: 1024px;
$breakpoint_desktop: 1280px;
$breakpoint_wd: 1600px;



// CSS Variables
// ==========================================================================

// Changement dynamiques des CSS vars en fonction des breakpoints
:root {
	--border-gutter: #{$border-gutter};

	--section-spacing: #{$section-spacing};
	--bloc-spacing: #{$bloc-spacing};
	--strate-spacing: #{$strate-spacing};

	--grid-gap: #{$grid-gap};
	--grid-gap-small: #{$grid-gap-small};

	--header-height: #{$header-height};
	--toolbar-height: #{$toolbar-height};

	@media screen and (max-width: map-get($breakpoints, 'tablet-landscape')) {
		--border-gutter: #{$border-gutter-tablet};
	}

	@media screen and (max-width: map-get($breakpoints, 'mobile')) {
		--border-gutter: #{$border-gutter-mobile};

		--section-spacing: #{$section-spacing-mobile};
		--bloc-spacing: #{$bloc-spacing-mobile};
		--strate-spacing: #{$strate-spacing-mobile};

		--grid-gap: #{$grid-gap-mobile};

		--header-height: #{$header-height-mobile};
		--toolbar-height: #{$toolbar-height-mobile};
	}
}

