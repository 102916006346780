.bloc-agenda {
	padding: 40px 0 70px;
	margin-top: 120px;
	@include tablet-portrait{
		margin-top: 95px;
	}
	@include mobile{
		margin-top: 60px;
	}
	+*.bloc{
		margin-top: 120px;
		@include tablet-portrait{
			margin-top: 95px;
		}
		@include mobile{
			margin-top: 60px;
		}
	}
	@extend %bgBtT;
	@extend %patternBG-top;
	@extend %patternBG-bottom;

	.container{
		&--head {
			text-align: center;

			h2{
				color: white;
				&:after{
					left: 50%;
					margin-left: -31px;
					color: white;
				}
			}
			
			.btn{
				margin-top: 0;
				margin-bottom: 15px;
				@include tablet-portrait{
					margin-bottom: 5px;
				}
				@include mobile{
					width: auto;
					float: none;
					clear: left;
					display: block;
					margin-right: auto;
					margin-left: auto;
					margin-bottom: 5px;
				}
				&:not(.btn-primary){
					background: white;
					color: $c_txt;
				}
				&.btn-primary{
					background-color: transparent;
					color: white;
					border-color: white;
				}
				&:not(:last-child){
					margin-right: 15px;
					@include mobile{
						margin-right: 0;
					}
				}
			}

			&:after{
				content: '';
				display: block;
				clear: both;
			}
		}
		&--cards {
			margin-left: -20px;
			margin-right: -20px;
			@include tablet-portrait{
				margin-left: 0;
				margin-right: 0;
			}
			.card{
				@include card-horizontale();
				@include card-agenda-white();
				float: left;
				width: calc(50% - 40px);
				margin: 20px;
				@include tablet-portrait{
					width:100%;
					margin: 20px 0;
				}
				@include mobile{
					margin: 10px 0;
				}
				&:nth-child(odd){
					clear: left;
				}

			}

			&:after{
				content: '';
				display: block;
				clear: both;
			}
		}
	}

}