.page-agenda{

	//Header
	.header-edito{
		&-wrapper{
			position: relative;
			.container{
				.surtitre{
					display: inline;
					&:not(:last-child){
						padding-right: 8px;
						&:after{
							content: ',';
						}
					}
				}
			}

			.dates{
				position: absolute;
				top: 0;
				right: 0;
				@include tablet-portrait{
					position: relative;
					right:auto;
					margin-top: 20px;
				}
				>div{
					text-align: right;
					white-space:nowrap;
					.date{
						color: white;
						font-size: 30px;
						line-height: 30px;
						font-weight: 700;
						@include tablet-landscape{
							font-size: 20px;
							line-height: 20px;
						}
					}
					.heure{
						color: white;
						font-size: 22px;
						line-height: 30px;
						@include tablet-landscape{
							font-size: 16px;
							line-height: 20px;
						}
					}
				}
				.finished{
					display: inline-block;
					font-size: 22px;
					line-height: 30px;
					font-weight: 600;
					color: $c_bg;
					background-color: white;
					padding: 2px 10px;
					border-radius:5px;
				}
			}
		}
	}

	//A Propos
	.a-propos{

		//Infos sup
		.infos-sup{
			&:not(:first-child){
				margin-top: 30px;
			}
			li{
				position: relative;
				padding-left: 30px;
				font-size: 16px;
				line-height: 24px;
				span{
					position: absolute;
					top: 6px;
					left: 0;
					color: $c_main;
					font-size: 13px;
					line-height: 1;
				}
			}
		}

		//Image
		.image{
			border-radius:50px 50px 5px 50px;
			overflow: hidden;
			&:not(:first-child){
				margin-top: 80px;
			}
			figure{
				background-color: transparent;
				img{
					width: 100%;
					height: auto;
				}
			}
			+*{
				margin-top: 120px;
			}
		}
	}



	.agenda-section{
		margin-top: 80px;
		@include tablet-portrait{
			margin-top: 60px;
		}
		@include mobile{
			margin-top: 40px;
		}
	}

	//Tarifs
	.tarifs{
		.gratuit{
			font-size: 18px;
			line-height: 24px;
			font-weight: 700;
		}
	}

	//Horaires
	.horaires{
		.dates{
			display: block;
			.ligne{
				border-bottom:1px solid rgba(0,0,0,.08);
				padding-bottom: 18px;
				margin-bottom: 20px;
				display: flex;
				justify-content: space-between;
				.jour{
					font-size: 16px;
					line-height: 24px;
					font-weight: 700;
				}
				.heure{
					font-size: 16px;
					line-height: 24px;
					font-weight: 400;
				}
			}

			.finished{
				display: inline-block;
				font-size: 18px;
				line-height: 24px;
				font-weight: 600;
				color: $c_bg;
			}
		}
	}

}