.cards-templates {
	.col-33, .col-50 {
		margin-bottom: 20px;
	}
}

.grille-presentation {
	.wrapper-small {
		.item {
			background-color: #DBC6BC;
		}
	}

	.wrapper-large {
		.item {
			background-color: #ECE7DA;
		}
	}

	.item {
		background-color: #CBC0B9;
		border: 1px dashed $c_sub;
		text-align: center;
		text-transform: uppercase;
		font-weight: 700;
		padding: 20px 0
	}

	.wrapper-columns {
		margin-top: -$small-margin;
	}

	.col-25, .col-33, .col-50, .col-66 {
		margin-top: $small-margin;
	}
}

.grille-libre {
	.container {
		display: flex;
		flex-wrap: wrap;
		position: relative;
		box-sizing: content-box;
		margin: 0 -5px;

		> * {
			display: inline-block;
			margin-left: 5px;
			margin-right: 5px;
			margin-top: 10px;
			// On veut avoir 6 logos per ligne dans la version desktop :
			width: calc(16.66% - 10px);
			background: transparent;
			@include tablet-portrait {
				// 4 per ligne dans la version portrait
				width: calc(25% - 10px);
			}
			@include mobile {
				width: calc(50% - 10px);
			}
			img {
				width: 100%;
				height: auto;
			}
		}
	}
}